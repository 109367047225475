export default {
  namespaced: true,
  state: {
    pathActivity: "admin/activity",
    currentPage: 1,
  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
  },
  actions: {
    async getListActivity({ state }, payload) {
      let response;
      try {
        response = await this._vm.$shopHttpWithToken().get(`${state.pathActivity}/list`, {
          params: {
            page: payload.page,
          },
        });
        return response;
      } catch (error) {
        return error;
      }
    },
    async createActivity({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$shopHttpWithToken()
          .post(state.pathActivity, payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async deleteActivity({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$shopHttpWithToken()
          .delete(`${state.pathActivity}/${payload}`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async updateActivityStatus({ state }, payload) {
      let response;
      try {
        response = await this._vm
            .$shopHttpWithToken()
            .put(`${state.pathActivity}/${payload.id}/status`, null, {
                  params: {
                    status: payload.status,
                  },
                }
            );
        return response;
      } catch (error) {
        return error;
      }
    },
    async editActivity({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$shopHttpWithToken()
          .put(`${state.pathActivity}/${payload.id}`, payload);
        return response;
      } catch (error) {
        return error;
      }
    },
    async searchActivity({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$shopHttpWithToken()
          .get(`${state.pathActivity}`, {
            params: {
              page: payload.page,
              adTitle: payload.search,
              size: payload.size,
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
