import router from "../../router";

export default {
  namespaced: true,
  state: {
    pathProducts: "admin/product",
    pathShops: "admin/shop",
    detail: false,
    detailProduct: {},
    products: [],
    bannedProducts: [],
    currentPage: 1,
  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setDetailView(state, payload) {
      state.detail = payload;
    },
    setDetailProduct(state, payload) {
      console.log(payload);
      state.detailProduct = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    setBannedProducts(state, payload) {
      state.bannedProducts = payload;
    },
  },
  actions: {
    createProduct({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathProducts}/create`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    editProduct({ state }, payload) {
      return this._vm
        .$shopHttpWithToken()
        .post(`${state.pathProducts}/edit`, payload)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    deleteProduct({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathProducts}/delete/${payload.id}`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    updateStatus({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathProducts}/listing`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    updateSku({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathProducts}/update`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    searchProduct({ state, commit }, payload) {
      return this._vm
        .$shopHttpWithToken()
        .get(`${state.pathProducts}/search`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getListProduct({ state, commit }, payload) {
      return this._vm
        .$shopHttpWithToken()
        .get(`${state.pathProducts}/list`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getListShop({ state, commit }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .get(`${state.pathShops}/list`, {
            params: {
              ...payload,
            },
          })
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    getListProductBanned({ state }, payload) {
      return this._vm
        .$httpWithToken()
        .get(`${state.pathProducts}/banned`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return err;
        });
    },
    async getProductById({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$shopHttpWithToken()
          .get(`${state.pathProducts}/${payload}`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getProductListById({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`products/account/${payload.id}`, {
            params: {
              ...payload.params,
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
  },
};
