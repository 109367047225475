export default {
  namespaced: true,
  state: {
    pathOrders: "admin/orders",
    pathOrdersTransaction: "admin/orders/transaction",
    pathOrdersShipment: "admin/orders/shipments/logistic/track/search",
    pathOrder: "shop/admin/order",
  },
  actions: {
    async getOrderCount({ state }) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathOrders}/count`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getListOrderByType({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathOrdersTransaction}/${payload.type}`, {
            params: {
              size: payload.size,
              page: payload.page,
              sort: "createAt,desc",
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getOrderById({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathOrders}/${payload}`);
        return response;
      } catch (error) {
        return error;
      }
    },
    async getOrderShipmentById({ state }, payload) {
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathOrdersShipment}`, {
            params: {
              orderId: payload,
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getOrderSearch({ state }, payload) {
      // search by noInvoice / sellerName / receiverName
      let response;
      try {
        response = await this._vm
          .$httpWithToken()
          .get(`${state.pathOrders}/search`, {
            params: {
              value: payload,
            },
          });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getOrderList({ state }, payload){
      let response;
      console.log("aaaa ",payload)
      try {
        response = await this._vm
            .$httpWithToken()
            .post(`${state.pathOrder}/list`, {
              orderCode: payload.orderCode,
              goodsName: payload.goodsName,
              orderStatus: payload.orderStatus,
              creatStartTimestamp: payload.creatStartTimestamp,
              creatEndTimestamp: payload.creatEndTimestamp,
            },{
              params: {
                pageSize: payload.pageSize,
                pageNum: payload.pageNum
              }
            });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getOrderDetailById({ state }, payload){
      let response;
      try {
        response = await this._vm
            .$httpWithToken()
            .get(`${state.pathOrder}/${payload}`, );
        return response;
      } catch (error) {
        return error;
      }
    }
  },
};
