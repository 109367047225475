<template>
    <div>
        <HeaderContent :list="items" label="List Sales Goods">
            <custom-input
                    placeholder="Search Goods"
                    class="mr-4"
                    width="200"
                    v-model="payloadSearch"
                    @keyup.enter="handleSearch"
            />
            <custom-button class="white--text" color="primary" @click="handleClick">Bust Goods</custom-button>
        </HeaderContent>

        <div class="table-container">
        <v-data-table
                :headers="headers"
                hide-default-footer
                disable-filtering
                disable-sort
                :items="goods"
                class="grey--text"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <span class="link" @click="openSku(item)">Goods Detail</span>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.searchName }}</td>
                    <td>{{ item.shopName }}</td>
                    <td>{{ item.createAt }}</td>
                    <td>
                        {{ item.salesCount }}
                    </td>
                    <td>{{ item.stock }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <div class="d-flex justify-space-between align-center">
                            <custom-button v-if="!item.status" class="white--text" color="primary"
                                           @click="openModalStart(item)">Listing Goods
                            </custom-button>
                            <custom-button v-if="item.status" class="grey-button" @click="openModalStart(item)">
                                deListing Goods
                            </custom-button>
                        </div>
                        <!-- Error Popup -->
                        <div v-if="isErrorPopupOpen" class="error-popup-overlay" @click="closeErrorPopup">
                            <div class="error-popup-content" @click.stop>
                                <button class="error-close-btn" @click="closeErrorPopup">X</button>
                                <p>{{ errorMessage }}</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-space-between align-center">
                            <v-btn @click="openModalDelete(item.id)" icon>
                                <v-icon x-small>$delete</v-icon>
                            </v-btn>
                        </div>
                    </td>
                </tr>

            </template>
        </v-data-table>
            <div v-if="loading" class="loading-indicator">
                <div class="spinner"></div>
            </div>
        </div>

        <Dialog-Delete
                title="确定删除这个商品吗?"
                :dialog="dialog"
                :loading="loading"
                @closeDialog="closeDialog"
                @handleDelete="handleDelete"
        ></Dialog-Delete>
        <Dialog-Table
                :dialog="showSku"
                :item="currentProduct"
                :loading="loading"
                @closeDialog="closeDialog"
                @handleUpdate="updateSkuInfo"
        ></Dialog-Table>
        <Listing-Table
                :dialog="showListing"
                :item="currentProduct"
                :loading="loading"
                @closeDialog="closeDialog"
                @handleUpdate="updateGoodsStatus"
        ></Listing-Table>

        <v-pagination
                class="d-flex justify-end"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                v-model="page"
                :length="totalPages"
                @input="getGoodsByPage"
                :total-visible="10"
        ></v-pagination>
    </div>
</template>

<script>
    import HeaderContent from "../../../containers/HeaderContent";
    import DialogDelete from "../../../components/material/Dialog/DialogDelete";
    import DialogConfirm from "../../../components/material/Dialog/DialogConfirm";
    import DialogTable from "./SkuDetailTable";
    import ListingTable from "./ListingSkuTable";
    import {mapActions, mapMutations, mapState} from "vuex";

    export default {
        components: {
            HeaderContent,
            DialogDelete,
            DialogConfirm,
            DialogTable,
            ListingTable,
        },
        data() {
            return {
                goodsId: "",
                currentProduct: null,
                showEditDialog: false,
                dialog: false,
                showSku: false,
                showListing: false,
                loading: false,
                page: 1,
                totalPages: 0,
                isErrorPopupOpen: false,
                errorMessage: '',
                items: [
                    {
                        text: "Manage Sales Goods",
                        disabled: false,
                        href: "/goods",
                    },
                ],
                payloadSearch: "",
                headers: [
                    {
                        text: "Details",
                        value: "no",
                        width: "120",
                        class: "whitesnow",
                    },
                    {
                        text: "Name",
                        value: "Name",
                        width: "180",
                        class: "whitesnow",
                    },
                    {
                        text: "Search Name",
                        value: "SearchName",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Shop Name",
                        value: "ShopName",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Create Date",
                        value: "CreateDate",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Sales Count",
                        value: "SalesCount",
                        width: "80",
                        class: "whitesnow",
                    },
                    {
                        text: "Stock【min - max】",
                        value: "Stock",
                        width: "120",
                        class: "whitesnow",
                    },
                    {
                        text: "Status",
                        value: "Status",
                        class: "whitesnow",
                        width: "50",
                    },
                    {
                        text: "Action",
                        value: "Action",
                        class: "whitesnow",
                        width: "50",
                    },
                    {
                        text: "",
                        value: "",
                        class: "whitesnow",
                        width: "80",
                    },
                ],
                goods: [],
            };
        },
        computed: {
            ...mapState('goods', ['currentPage']),
        },
        created() {
            this.page = this.currentPage;

            this.getResponseGoods();
        },
        methods: {
            ...mapMutations('goods', ['setCurrentPage']),
            ...mapActions({
                listGoods: "goods/getListGoods",
                updateStatus: "goods/updateStatus",
                updateSku: "goods/updateSku",
                deleteGoods: "goods/deleteGoods",
                searchGoods: "goods/searchGoods",
            }),
            async getResponseGoods() {
                this.loading = true;
                const payload = {
                    page: this.page - 1,
                    size: 10,
                    sort: `createAt,desc`
                };
                const response = await this.listGoods(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    this.formatingResponse(responseData);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            async updateSkuInfo(item) {
                this.loading = true;
                const skuList = item.skuList.map((res, index) => {
                    return {
                        id: res.id,
                        skuId: res.skuId,
                        price: res.price,
                        salesPrice: res.salesPrice,
                        stock: res.stock,
                        priceUnit: res.priceUnit,
                    };
                });
                const payload = {
                    id: item.id,
                    skuList: skuList,
                    name: item.name,
                    searchName: item.searchName,
                    description: item.description
                };
                const response = await this.updateSku(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.showListing = false;
                    this.showSku = false;
                    this.goodsId = "";
                    this.loading = false;
                    await this.getResponseGoods();
                } else {
                    this.dialog = false;
                    this.showListing = false;
                    this.showSku = false;
                    this.goodsId = "";
                    this.loading = false;
                    return response;
                }
            },
            async updateGoodsStatus(item) {
                this.loading = true;
                let enabled = false;
                const skuList = item.skuList.map((res, index) => {
                    if(res.enabled){
                        enabled = true;
                    }
                    return {
                        id: res.id,
                        skuId: res.skuId,
                        price: res.price,
                        salesPrice: res.salesPrice,
                        stock: res.stock,
                        priceUnit: res.priceUnit,
                        enabled: res.enabled
                    };
                });
                const payload = {
                    id: item.id,
                    productId: item.productId,
                    skuList: skuList,
                    name: item.name,
                    searchName: item.searchName,
                    description: item.description,
                    enabled: enabled,
                    isFlushed: item.isFlushed,
                };
                const response = await this.updateStatus(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.showListing = false;
                    this.goodsId = "";
                    this.loading = false;
                    await this.getResponseGoods();
                } else {
                    this.dialog = false;
                    this.showListing = false;
                    this.goodsId = "";
                    this.loading = false;
                    return response;
                }
            },
            closeErrorPopup() {
                this.isErrorPopupOpen = false;
            },
            async getGoodsByPage() {
                this.loading = true;
                const payload = {
                    page: this.page - 1,
                    size: 10,
                    sort: `createAt,desc`
                };
                const response = await this.listGoods(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    this.formatingResponse(responseData);

                    this.setCurrentPage(this.page);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            async handleSearch() {
                this.loading = true;
                const payload = {
                    page: 0,
                    size: 10,
                    sort: `createAt,desc`,
                    name: this.payloadSearch
                };
                const response = await this.searchGoods(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    console.info(responseData)
                    this.formatingResponse(responseData);
                    this.setCurrentPage(1);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            formatingResponse(response) {
                this.totalPages = response.totalPages;
                const content = response.content;
                this.goods = content.map((res, index) => {
                    const values = res.skuList;
                    let maxStock = Math.max(...values.map(item => item.stock));
                    let minStock = Math.min(...values.map(item => item.stock));

                    return {
                        id: res.id,
                        name: res.name,
                        searchName: res.searchName,
                        description: res.description,
                        skuList: res.skuList,
                        skuSpecNames: res.skuSpecNames,
                        salesCount: res.salesCount ? res.salesCount : 0,
                        createAt: res.createAt,
                        productId: res.productId,
                        shopId: res.shopId,
                        shopName: res.shopName,
                        status: res.enabled,
                        isFlushed: res.isFlushed,
                        stock: '[ ' + minStock + ' - ' + maxStock + ' ]',
                        no: index + 1,
                    };
                });
            },
            handleClick() {
                this.$router.push({
                    name: "postGoods",
                    params: {
                        id: null
                    },
                });
            },
            openSku(payload) {
                this.showSku = true;
                this.currentProduct = payload;
            },
            openModalDelete(id) {
                this.dialog = true;
                this.goodsId = id;
            },
            openModalStart(payload) {
                this.showListing = true;
                this.currentProduct = payload;
            },
            closeDialog() {
                this.dialog = false;
                this.showSku = false;
                this.showListing = false;
                this.goodsId = "";
                this.currentProduct = null;
            },
            async handleDelete() {
                this.loading = true;
                const payload = {
                    id: this.goodsId
                };
                const response = await this.deleteGoods(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.goodsId = "";
                    this.loading = false;
                    await this.getResponseGoods();
                } else {
                    const responseString = JSON.stringify(response, null, 2);
                    if (responseString.includes('status code 400')) {
                        this.errorMessage = 'Can\'t delete goods. The goods is on-selling.';
                        this.isErrorPopupOpen = true;
                        setTimeout(() => {
                            this.isErrorPopupOpen = false
                        }, 2000)
                    }
                    this.dialog = false;
                    this.goodsId = "";
                    this.loading = false;
                    return response;
                }
            },
        },
    };
</script>

<style lang="sass" scoped>
.image
    &__box
        width: 50px
        height: 50px
        background-color: grey
        border-radius: 5px
        overflow: hidden

    &__failed
        background: grey

    &__container
        padding: 10px 0

.manage
    &__box
        width: 80px

.grey-button
    background-color: #DEDEDE
    color: black
    border: none

.link
    color: #007bff
    text-decoration: underline
    cursor: pointer

.error-popup-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.2)
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000

.error-popup-content
    position: relative
    padding-top: 30px
    padding-bottom: 10px
    background: white
    border-radius: 8px
    max-width: 300px
    width: 90%
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

/* Close button styling */

.error-close-btn
    position: absolute
    top: 10px
    right: 10px
    border: none
    background: red
    font-size: 16px
    cursor: pointer
    color: white
    border-radius: 50%
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center

.loading-indicator
    position: absolute // Absolute position inside the table container
    top: 50%           // Center vertically
    left: 50%
    transform: translate(-50%, -50%)
    background-color: rgba(255, 255, 255, 0.8)
    /* 背景半透明 */
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000
    width: 100%
    height: 100%
/* 确保在最上层 */



.spinner
    border: 8px solid #f3f3f3
    /* 边框颜色 */
    border-top: 8px solid red
    /* 上边框红色 */
    border-radius: 50%
    width: 50px
    /* 宽度 */
    height: 50px
    /* 高度 */
    animation: spin 1s linear infinite
/* 旋转动画 */

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.table-container
    position: relative

</style>
