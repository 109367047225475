export default {
  namespaced: true,
  state: {
    pathMaster: "admin",
  },
  actions: {
    getListCategory({ state }) {
      return this._vm
        .$shopHttpWithToken()
        .get(`${state.pathMaster}/product-categories/list`)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    getListCategoryAttr({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .get(`${state.pathMaster}/product-categories/attribute/${payload.id}`)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            throw err;
          });
    },
    reorderCategory({state}, payload) {
      console.log({payload})
      return this._vm
        .$httpWithToken()
        .post(`${state.pathMaster}/product-categories/reordered`, payload)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    createCategory({ state }, payload) {
      return this._vm
        .$httpWithToken()
        .post(`${state.pathMaster}/product-categories`, {
          ...payload,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    editCategory({ state }, payload) {
      return this._vm
        .$httpWithToken()
        .put(`${state.pathMaster}/product-categories/${payload.id}`, {
          ...payload.params,
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
};
