<template>
  <v-dialog v-model="orderDetailDiglog" max-width="1000px" @click:outside="closeOrderDetail">
    <v-card
        v-if="!orderDetailItems"
        class="d-flex justify-center align-center py-12"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-card>

    <v-card v-else class="charcoal--text detail__card">
      <v-row no-gutters>
        <v-col cols="2"></v-col>
        <v-col cols="8" align-self="center" class="text-center">
          <h4>Detail Order</h4>
        </v-col>
        <v-col cols="2" align-self="center" class="text-right">
          <v-btn icon @click="closeOrderDetail">
            <v-icon size="16">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="orderDetailItems" no-gutters class="font-14 mt-10">
        <v-col cols="4" class="d-flex flex-column">
          <span class="font-12 silver--text">No. Order</span>
          <span class="font-weight-medium">{{ orderDetailItems.orderCode }}</span>
        </v-col>
        <v-col cols="4" class="d-flex flex-column">
          <span class="font-12 silver--text">Tgl Pembelian</span>
          <span class="font-weight-medium">{{ formatTimestamp(orderDetailItems.creatTimestamp) }}</span>
        </v-col>
        <v-col cols="4" class="d-flex flex-column">
          <span class="font-12 silver--text">Status</span>
          <span class="font-weight-medium">{{ orderDetailItems.orderStatus }}</span>
        </v-col>
      </v-row>

      <v-divider class="mt-6"></v-divider>

      <v-row v-if="orderDetailItems.orderProduct" no-gutters class="font-14 mt-5">
        <v-col cols="12">
          <span class="font-12 silver--text">Produk</span>
        </v-col>
        <v-col cols="2" class="d-flex flex-column mt-2">
          <v-img
              :src="orderDetailItems.orderProduct.productCoverUrl"
              width="72"
          ></v-img>
        </v-col>
        <v-col cols="8" class="d-flex flex-column mt-2">
          <span class="font-weight-medium">
            {{ orderDetailItems.orderProduct.productName }}
          </span>
          <span class="font-weight-bold black--text">
            {{ orderDetailItems.orderProduct.productQuantity }} x {{ formatPrice(orderDetailItems.orderProduct.productPrice) }}
          </span>
        </v-col>
        <!-- <v-col v-if="orderDetailItems.goods" cols="12" class="d-flex flex-column mt-5">
          <span class="font-12 font-weight-bold black&#45;&#45;text">Notes</span>
          <span>{{ orderDetailItems.goods.note || "-" }}</span>
        </v-col>-->
      </v-row>

      <v-divider class="mt-6"></v-divider>

      <v-row v-if="orderDetailItems.orderLogistics" no-gutters class="font-14 mt-5">
        <v-col cols="6">
          <div class="d-flex flex-column">
            <span class="font-12 font-weight-bold black--text">Pengiriman</span>
            <span>
              <!-- {{ `${detailItems.orderShipment.service} - ${detailItems.orderShipment.courier}` }} -->
              {{ orderDetailItems.orderLogistics.logisticsType || "-" }}
            </span>
          </div>
          <div class="d-flex flex-column mt-8">
            <span class="font-12 font-weight-bold black--text">Pengirim</span>
            <span> {{ orderDetailItems.orderLogistics.originName || "-" }} </span>
            <span> {{ orderDetailItems.orderLogistics.originPhoneNumber || "-" }} </span>
          </div>
          <div class="d-flex flex-column mt-4">
            <span class="font-12 font-weight-bold black--text">Alamat</span>
            <span> {{ orderDetailItems.orderLogistics.originAddress || "-" }} </span>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-column">
            <span class="font-12 font-weight-bold black--text">Nomor Resi</span>
            <span> {{ orderDetailItems.orderLogistics.logisticsCode || "-" }} </span>
          </div>
          <div class="d-flex flex-column mt-8">
            <span class="font-12 font-weight-bold black--text">Penerima</span>
            <span> {{ orderDetailItems.orderLogistics.destinationName || "-" }} </span>
            <span> {{ orderDetailItems.orderLogistics.destinationPhoneNumber || "-" }} </span>
          </div>
          <div class="d-flex flex-column mt-4">
            <span class="font-12 font-weight-bold black--text">Alamat</span>
            <span> {{ orderDetailItems.orderLogistics.destinationAddress || "-" }} </span>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-6"></v-divider>

      <v-row v-if="orderDetailItems.orderPayment" no-gutters class="font-14 mt-5">
        <v-col cols="12">
          <span class="font-12 font-weight-bold black--text">Pembayaran</span>
        </v-col>
        <v-col cols="6" class="d-flex flex-column mt-2">
          <span>Total Harga Barang</span>
          <span>Total Ongkos Kirim</span>
          <span>Total Pembayaran</span>
          <span>Metode Pembayaran</span>
        </v-col>
        <v-col cols="6" class="d-flex flex-column align-end mt-2">
          <span class="font-weight-medium">
            {{ formatPrice(orderDetailItems.orderPayment.totalAmount) }}
          </span>
          <span class="font-weight-medium">
            {{ formatPrice(orderDetailItems.orderPayment.freightAmount) }}
          </span>
          <span class="font-weight-medium primary--text">
            {{ formatPrice(null) || "-" }}
          </span>
          <span class="font-weight-medium">
            <!-- {{ `${detailItems.payment.bank || "-"} ${detailItems.payment.type || ""}` }}-->
            {{ `${orderDetailItems.orderPayment.paymentType || "-"} ` }}
          </span>
        </v-col>
      </v-row>


    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
      "orderDetailDiglog",
      "orderDetailItems"
  ],
  methods: {
    closeOrderDetail() {
      this.$emit("closeOrderDetail");
    },
    formatTimestamp(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
}
</script>

<style lang="sass" scoped>
.detail
  &__card
    padding: 32px 42px 42px
.font-10
  font-size: 10px
.font-12
  font-size: 12px
.font-14
  font-size: 14px
</style>