import router from "../../router";

export default {
  namespaced: true,
  state: {
    pathGoods: "admin/goods",
    detail: false,
    detailProduct: {},
    products: [],
    bannedProducts: [],
    currentPage: 1,
  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setDetailView(state, payload) {
      state.detail = payload;
    },
    setDetailProduct(state, payload) {
      console.log(payload);
      state.detailProduct = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    setBannedProducts(state, payload) {
      state.bannedProducts = payload;
    },
  },
  actions: {
    deleteGoods({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathGoods}/delete/${payload.id}`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    updateStatus({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathGoods}/listing`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    updateSku({ state }, payload) {
      return this._vm
          .$shopHttpWithToken()
          .post(`${state.pathGoods}/update`, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    searchGoods({ state, commit }, payload) {
      return this._vm
        .$shopHttpWithToken()
        .get(`${state.pathGoods}/search`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getListGoods({ state, commit }, payload) {
      return this._vm
        .$shopHttpWithToken()
        .get(`${state.pathGoods}/list`, {
          params: {
            ...payload,
          },
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};
