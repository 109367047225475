<template>
  <v-dialog v-model="dialog" persistent width="1200">
    <v-card>
      <v-card-text>
        <div style="padding: 10px;border-radius: 8px;font-size: 14px;">
          <h3>Goods Info</h3>
          <div v-if="item && item.skuSpecNames && item.skuSpecNames.length > 0" class="table-container" style="padding-top: 10px;">
            <custom-input
                    label="Name"
                    name="Name"
                    rules="required"
                    v-model="item.name"/>
            <custom-input
                    label="SearchName"
                    v-model="item.searchName"
                    rules="required"
                    name="SearchName"
            />
            <custom-input
                    label="Description"
                    v-model="item.description"
                    rules="required"
                    name="Description"
            />
            <table style="border: 1px solid #DDDDDD;border-collapse: separate;border-spacing: 10px;border-radius: 8px;">
              <thead>
              <tr style="text-align: left;border: 1px solid #DDDDDD;">
                <th></th>
                <th v-for="(spec, specIndex) in item.skuSpecNames" :key="specIndex">{{ spec }}</th>
                <th>Price</th>
                <th>Sales Price</th>
                <th>Stock</th>
                <th>Price Unit</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in item.skuList" :key="row.index" style="text-align: left;border: 1px solid #DDDDDD;">
                <td style="width: auto;min-width: 50px;">{{ 'Sku ' + (index + 1) }}:</td>
                <td style="border: 1px solid #DDDDDD;border-radius: 5px;" v-for="(spec, specIndex) in row.specValues" :key="'spec-' + specIndex">
                  <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" :value="spec" readonly/>
                </td>
                <td style="border: 1px solid #DDDDDD;border-radius: 5px;" >
                  <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" v-model="row.price" type="number"/>
                </td>
                <td style="border: 1px solid #DDDDDD;border-radius: 5px;" >
                  <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" v-model="row.salesPrice" type="number"/>
                </td>
                <td style="border: 1px solid #DDDDDD;border-radius: 5px;" >
                  <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" v-model="row.stock" type="number"/>
                </td>
                <td style="border: 1px solid #DDDDDD;border-radius: 5px;" >
                  <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" v-model="row.priceUnit"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <custom-button @click="closeDialog">Cancel</custom-button>
        <custom-button
          color="primary"
          class="white--text ml-6"
          @click="handleLocalUpdate"
          :loading="loading"
          >Update</custom-button
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
  props: {
    item: {
      type: Object,
    },
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    handleLocalUpdate() {
      this.$emit("handleUpdate", this.item);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
