<template>
    <div>
        <HeaderContent :list="items" label="List Product">
            <custom-input
                    placeholder="Search Product"
                    class="mr-4"
                    width="200"
                    v-model="payloadSearch"
                    @keyup.enter="handleSearch"
            />
            <custom-button class="white--text" color="primary" @click="handleClick">Bust Product</custom-button>
        </HeaderContent>

        <div class="table-container">
        <v-data-table
                :headers="headers"
                hide-default-footer
                disable-filtering
                disable-sort
                :items="products"
                class="grey--text"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <span class="link" @click="moveEdit(item)">View Prodcut Detail</span>
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.productCategoryName }}</td>
                    <td>{{ item.createAt }}</td>
                    <td>
                        <span class="link" @click="openSku(item)">Sku Detail</span>
                    </td>
                    <td>
                        <div class="d-flex justify-space-between align-center">
                            <custom-button class="white--text" color="primary"
                                           @click="openModalStart(item)">Listing New Goods
                            </custom-button>
                        </div>
                        <!-- Error Popup -->
                        <div v-if="isErrorPopupOpen" class="error-popup-overlay" @click="closeErrorPopup">
                            <div class="error-popup-content" @click.stop>
                                <button class="error-close-btn" @click="closeErrorPopup">X</button>
                                <p>{{ errorMessage }}</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-space-between align-center">
                            <v-btn @click="openModalDelete(item.id)" icon>
                                <v-icon x-small>$delete</v-icon>
                            </v-btn>
                        </div>
                    </td>
                </tr>

            </template>
        </v-data-table>
            <div v-if="loading" class="loading-indicator">
                <div class="spinner"></div>
            </div>
        </div>

        <Dialog-Delete
                title="确定删除这个商品吗?"
                :dialog="dialog"
                :loading="loading"
                @closeDialog="closeDialog"
                @handleDelete="handleDelete"
        ></Dialog-Delete>
        <Dialog-Table
                :dialog="showSku"
                :item="currentProduct"
                :loading="loading"
                @closeDialog="closeDialog"
                @handleUpdate="updateSkuInfo"
        ></Dialog-Table>

        <v-pagination
                class="d-flex justify-end"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                v-model="page"
                :length="totalPages"
                @input="getProductByPage"
                :total-visible="10"
        ></v-pagination>
    </div>
</template>

<script>
    import HeaderContent from "../../../containers/HeaderContent";
    import DialogDelete from "../../../components/material/Dialog/DialogDelete";
    import DialogConfirm from "../../../components/material/Dialog/DialogConfirm";
    import DialogTable from "./SkuDetailTable";
    import {mapActions, mapMutations, mapState} from "vuex";

    export default {
        components: {
            HeaderContent,
            DialogDelete,
            DialogConfirm,
            DialogTable,
        },
        data() {
            return {
                productId: "",
                currentProduct: null,
                showEditDialog: false,
                dialog: false,
                showSku: false,
                showListing: false,
                loading: false,
                page: 1,
                totalPages: 0,
                isErrorPopupOpen: false,
                errorMessage: '',
                items: [
                    {
                        text: "Manage Product",
                        disabled: false,
                        href: "/product",
                    },
                ],
                payloadSearch: "",
                headers: [
                    {
                        text: "Details",
                        value: "no",
                        width: "120",
                        class: "whitesnow",
                    },
                    {
                        text: "Name",
                        value: "Name",
                        width: "180",
                        class: "whitesnow",
                    },
                    {
                        text: "Category",
                        value: "Category",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Create Date",
                        value: "CreateDate",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Sku List",
                        value: "SkuList",
                        width: "100",
                        class: "whitesnow",
                    },
                    {
                        text: "Action",
                        value: "Action",
                        class: "whitesnow",
                        width: "50",
                    },
                    {
                        text: "",
                        value: "",
                        class: "whitesnow",
                        width: "80",
                    },
                ],
                products: [],
            };
        },
        computed: {
            ...mapState('product', ['currentPage']),
        },
        created() {
            this.page = this.currentPage;

            this.getResponseProduct();
        },
        methods: {
            ...mapMutations('product', ['setCurrentPage']),
            ...mapActions({
                listProduct: "product/getListProduct",
                updateStatus: "product/updateStatus",
                updateSku: "product/updateSku",
                deleteProduct: "product/deleteProduct",
                searchProduct: "product/searchProduct",
            }),
            async getResponseProduct() {
                this.loading = true;
                const payload = {
                    page: this.page - 1,
                    size: 10,
                    sort: `createAt,desc`
                };
                const response = await this.listProduct(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    this.formatingResponse(responseData);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            async updateSkuInfo(item) {
                this.loading = true;
                const skuList = item.skuList.map((res, index) => {
                    return {
                        id: res.id,
                        price: res.price,
                        salesPrice: res.salesPrice,
                        stock: res.stock,
                        priceUnit: res.priceUnit,
                    };
                });
                const payload = {
                    id: item.id,
                    skuList: skuList
                };
                const response = await this.updateSku(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.showListing = false;
                    this.showSku = false;
                    this.productId = "";
                    this.loading = false;
                    await this.getResponseProduct();
                } else {
                    this.dialog = false;
                    this.showListing = false;
                    this.showSku = false;
                    this.productId = "";
                    this.loading = false;
                    return response;
                }
            },
            async updateProductStatus(item) {
                this.loading = true;
                let enabled = false;
                const skuList = item.skuList.map((res, index) => {
                    if(res.enabled){
                        enabled = true;
                    }
                    return {
                        id: res.id,
                        enabled: res.enabled
                    };
                });
                const payload = {
                    id: item.id,
                    enabled: enabled,
                    skuList: skuList
                };
                const response = await this.updateStatus(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.showListing = false;
                    this.productId = "";
                    this.loading = false;
                    await this.getResponseProduct();
                } else {
                    this.dialog = false;
                    this.showListing = false;
                    this.productId = "";
                    this.loading = false;
                    return response;
                }
            },
            closeErrorPopup() {
                this.isErrorPopupOpen = false;
            },
            async getProductByPage() {
                this.loading = true;
                const payload = {
                    page: this.page - 1,
                    size: 10,
                    sort: `createAt,desc`
                };
                const response = await this.listProduct(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    this.formatingResponse(responseData);

                    this.setCurrentPage(this.page);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            async handleSearch() {
                this.loading = true;
                const payload = {
                    page: 0,
                    size: 10,
                    sort: `createAt,desc`,
                    name: this.payloadSearch
                };
                const response = await this.searchProduct(payload);
                if (response.status === 200) {
                    const responseData = response.data.data;
                    console.info(responseData)
                    this.formatingResponse(responseData);
                    this.setCurrentPage(1);
                    this.loading = false;
                } else {
                    this.loading = false;
                    return response;
                }
            },
            formatingResponse(response) {
                this.totalPages = response.totalPages;
                const content = response.content;
                this.products = content.map((res, index) => {
                    const values = res.skuList;
                    let maxStock = Math.max(...values.map(item => item.stock));
                    let minStock = Math.min(...values.map(item => item.stock));

                    return {
                        id: res.id,
                        name: res.name,
                        searchName: res.searchName,
                        brand: res.brand,
                        productCategoryId: res.productCategoryId,
                        productCategoryName: res.productCategoryName,
                        description: res.description,
                        detailInfo: res.detailInfo,
                        paramsKeyValue: res.paramsKeyValue,
                        detailParams: res.detailParams,
                        skuSpecs: res.skuSpecs,
                        skuList: res.skuList,
                        medias: res.medias,
                        createAt: res.createAt,
                        productAttributes: res.productAttributes,
                        productType: res.productType,
                        shopId: res.shopId,
                        status: res.enabled,
                        stock: '[ ' + minStock + ' - ' + maxStock + ' ]',
                        no: index + 1,
                    };
                });
            },
            handleClick() {
                this.$router.push({
                    name: "postProduct",
                    params: {
                        id: null,
                        name: "",
                        searchName: "",
                        brand: "",
                        productCategoryId: 0,
                        productCategoryName: "",
                        description: "",
                        detailInfo: "",
                        paramsKeyValue: JSON.stringify([{
                            index: 1,
                            key: "",
                            value: "",
                        }]),
                        detailParams: "",
                        skuSpecs: JSON.stringify([{attrId: 0, name: '', values: [], coverOptions: []}]),
                        skuList: JSON.stringify([{
                            index: 1, specs: [], price: 0, stock: 0, salesPrice: 0, priceUnit: ''
                        }]),
                        medias: JSON.stringify([]),
                        createAt: "",
                        productAttributes: JSON.stringify([]),
                        productType: 0,
                        shopId: 0,
                        status: false,
                    },
                });
            },
            moveEdit(payload) {
                this.$router.push({
                    name: "postProduct",
                    params: {
                        id: payload.id,
                        name: payload.name,
                        searchName: payload.searchName,
                        brand: payload.brand,
                        productCategoryId: payload.productCategoryId,
                        productCategoryName: payload.productCategoryName,
                        description: payload.description,
                        detailInfo: payload.detailInfo,
                        paramsKeyValue: JSON.stringify(payload.paramsKeyValue ? payload.paramsKeyValue : [{
                            index: 1,
                            key: "",
                            value: "",
                        }]),
                        detailParams: payload.detailParams,
                        skuSpecs: JSON.stringify(payload.skuSpecs),
                        skuList: JSON.stringify(payload.skuList),
                        medias: JSON.stringify(payload.medias),
                        createAt: payload.createAt,
                        productAttributes: JSON.stringify(payload.productAttributes),
                        productType: payload.productType,
                        shopId: payload.shopId,
                        status: payload.status,
                    },
                });
            },
            openSku(payload) {
                this.showSku = true;
                this.currentProduct = payload;
            },
            openModalDelete(id) {
                this.dialog = true;
                this.productId = id;
            },
            openModalStart(payload) {
                this.$router.push({
                    name: "postGoods",
                    params: {
                        id: payload.id,
                        name: payload.name,
                        searchName: payload.searchName,
                        description: payload.description,
                        skuSpecNames:  JSON.stringify(payload.skuSpecs.map(spec => spec.name)),
                        skuList: JSON.stringify(payload.skuList.map((sku, idx) => ({
                            index: idx + 1,
                            specValues: sku.specs.map(spec => spec.value),
                            skuId: sku.id,
                            price: sku.price ? sku.price : 0,
                            stock: sku.stock ? sku.stock : 0,
                            salesPrice: sku.salesPrice ? sku.salesPrice : 0,
                            priceUnit: sku.priceUnit ? sku.priceUnit : 'Rp',
                            enabled: false
                        }))),
                        createAt: payload.createAt,
                        categoryId: payload.productCategoryId,
                        medias: JSON.stringify(payload.medias)
                    },
                });
            },
            closeDialog() {
                this.dialog = false;
                this.showSku = false;
                this.showListing = false;
                this.productId = "";
                this.currentProduct = null;
            },
            async handleDelete() {
                this.loading = true;
                const payload = {
                    id: this.productId
                };
                const response = await this.deleteProduct(payload);
                if (response.status === 200) {
                    this.dialog = false;
                    this.productId = "";
                    this.loading = false;
                    await this.getResponseProduct();
                } else {
                    const responseString = JSON.stringify(response, null, 2);
                    if (responseString.includes('status code 400')) {
                        this.errorMessage = 'Can\'t delete product. The product is on-selling.';
                        this.isErrorPopupOpen = true;
                        setTimeout(() => {
                            this.isErrorPopupOpen = false
                        }, 2000)
                    }
                    this.dialog = false;
                    this.productId = "";
                    this.loading = false;
                    return response;
                }
            },
        },
    };
</script>

<style lang="sass" scoped>
.image
    &__box
        width: 50px
        height: 50px
        background-color: grey
        border-radius: 5px
        overflow: hidden

    &__failed
        background: grey

    &__container
        padding: 10px 0

.manage
    &__box
        width: 80px

.grey-button
    background-color: #DEDEDE
    color: black
    border: none

.link
    color: #007bff
    text-decoration: underline
    cursor: pointer

.error-popup-overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.2)
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000

.error-popup-content
    position: relative
    padding-top: 30px
    padding-bottom: 10px
    background: white
    border-radius: 8px
    max-width: 300px
    width: 90%
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

/* Close button styling */

.error-close-btn
    position: absolute
    top: 10px
    right: 10px
    border: none
    background: red
    font-size: 16px
    cursor: pointer
    color: white
    border-radius: 50%
    width: 20px
    height: 20px
    display: flex
    align-items: center
    justify-content: center

.loading-indicator
    position: absolute // Absolute position inside the table container
    top: 50%           // Center vertically
    left: 50%
    transform: translate(-50%, -50%)
    background-color: rgba(255, 255, 255, 0.8)
    /* 背景半透明 */
    display: flex
    justify-content: center
    align-items: center
    z-index: 1000
    width: 100%
    height: 100%
/* 确保在最上层 */



.spinner
    border: 8px solid #f3f3f3
    /* 边框颜色 */
    border-top: 8px solid red
    /* 上边框红色 */
    border-radius: 50%
    width: 50px
    /* 宽度 */
    height: 50px
    /* 高度 */
    animation: spin 1s linear infinite
/* 旋转动画 */

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.table-container
    position: relative

</style>
