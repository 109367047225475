<template>
  <div>
    <HeaderContent label="Buat Activity/Voucher" :list="items" />
    <div class="mt-4" />
    <FormActivity
      :ad="ad"
      @onSubmit="onSubmit"
      labelButton="Buat Activity"
      :loading="loading"
      status="create"
    />
    <v-snackbar top right v-model="alertSuccess" color="success">
      Post Success
    </v-snackbar>
    <v-snackbar top right v-model="alertError" color="error">
      {{errorMessage}}
    </v-snackbar>
  </div>
</template>

<script>
import FormActivity from "../../../containers/Form/formActivity";
import HeaderContent from "../../../containers/HeaderContent";
import { mapActions } from "vuex";

export default {
  components: {
    HeaderContent,
    FormActivity,
  },
  data() {
    return {
      loading: false,
      alertSuccess: false,
      alertError: false,
      errorMessage: 'Post Failed',
      ad: {
        adTitle: "",
        duration: "",
        medias: [],
        adCampaignType : '',
        accountIds: [],
        accountType: '',
        isOfficial: ''
      },
      image: "",
      status: "",
      items: [
        {
          text: "Manage Activity",
          disabled: false,
          href: "/activity",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      createActivity: "activity/createActivity",
    }),
    async onSubmit() {
      this.loading = true;
      const response = await this.createActivity(this.ad);
      if (response.status === 201) {
        this.alertSuccess = true;
        setTimeout(() => {
          this.$router.push("/activity");
          this.alertSuccess = false;
        }, 500);
        this.loading = false;
      } else {
        const responseString = JSON.stringify(response, null, 2);
        if(responseString.includes('status code 400')){
          this.errorMessage = 'Kamu belum pernah memposting video,jadi kamu tidak dapat memasang iklan.'
        }
        this.loading = false;
        this.alertError = true;
        setTimeout(() => {
          this.alertError = false;
        }, 3000);
      }
    },
  },
};
</script>
