<template>
    <div>
        <HeaderContent :list="items" label="Post Goods"/>
        <div style="display: flex;flex: 1;">
            <div class="left-panel">
                <div class="form__section mt-6">
                    <div class="__header">
                        <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Listing Product</span>
                    </div>
                    <div class="form__selectAccount" >
                        <div v-if="params.productId !== 0" @click="showProductDialog = true" class="selected-accounts">
                            <custom-select :placeholder="selectedProductName"></custom-select>
                        </div>
                        <div v-if="params.productId === 0" @click="showProductDialog = true">
                            <custom-select placeholder="Select Listing Product"></custom-select>
                        </div>
                    </div>
                    <v-dialog v-model="showProductDialog" max-width="600px" style="z-index: 1">
                        <v-card>
                            <v-card-title>
                                <v-text-field
                                        v-model="searchQuery"
                                        label="Search Product"
                                        prepend-icon="mdi-magnify"
                                        @keydown.enter="searchData"
                                        :loading="isLoadingSearch"
                                />
                                <v-progress-linear
                                        indeterminate
                                        class="mt-3"
                                        v-if="isLoadingSearch"
                                ></v-progress-linear>
                            </v-card-title>
                            <v-card-text>
                                <v-list>
                                    <div v-for="product in filteredProduct"
                                         :key="product.productId">
                                        <label
                                                :for="'checkbox-' + product.productId"
                                                class="account-row"
                                                style="display: flex;align-items: center; padding: 13px;"
                                        >
                                            <img :src="product.productPhoto[0]" style="width: 30px;height: 30px;margin-right: 16px;" alt=""/>
                                            <div>
                                                <span>{{ product.productName }}</span>
                                            </div>
                                            <div style="margin-left: auto;width: 9px;height: 9px;margin-right: 16px;justify-content: center;align-items: center;">
                                                <input type="checkbox" :value="product.productId"
                                                       :id="'checkbox-' + product.productId"
                                                       :name="'checkbox-' + product.productId"
                                                       :checked="params.productId === product.productId"
                                                       @change="handleProductCheckboxChange($event, product)"
                                                       />
                                            </div>
                                        </label>
                                    </div>
                                        <div style="display: flex;align-items: center;justify-content: center;margin-top: 16px;">
                                            <button @click="fetchDataByLoadMore(page + 1)">
                                                <span v-if="isLoadingProduct">loading...</span>
                                                <span v-else-if="isNoMoreProduct">no more data</span>
                                                <span v-else>load more...</span>
                                            </button>
                                            <div v-if="isLoadingProduct" style="margin-left: 10px;">
                                                <i class="fas fa-spinner fa-spin"></i>
                                            </div>
                                        </div>

                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="white--text red" @click="applySelectedProduct">Apply</v-btn>
                                <v-btn text @click="showProductDialog = false">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="__header">
                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Goods Main Photo</span>
                </div>
                <div class="__image-list" v-if="coverImages && coverImages.length !== 0" >
                    <div v-for="(url, index) in coverImages" :key="index" class="__image-wrapper">
                        <div style="display: flex;align-items: center;flex-direction: column;">
                        <img :src="url" @click="openPopup(index)" class="__image" alt=""/>
                        <input type="radio"
                               id="radiourl"
                               name="radiourl"
                               @change="handleMainPhotoCheckboxChange($event, index)"
                               style="display: flex;align-items: center;justify-content: center;cursor: pointer;size:20px;margin-top: 5px;padding-bottom: 5px;"
                        />
                        </div>
                    </div>
                    <div v-if="isPopupOpen" class="popup" @click="closePopup">
                        <div class="popup-content" v-if="coverImages && coverImages[currentImageIndex]">
                            <!-- Close button -->
                            <button class="close-btn" @click="closePopup">X</button>
                            <!-- Image inside the popup -->
                            <img :src="coverImages[currentImageIndex]" class="popup-image" />
                        </div>
                    </div>
                </div>
                <br/>
                <div class="__header">
                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Listing Shop</span>
                </div>
                <ShopSelect v-model="params.shopId"
                                holderText="Please select"
                                :options="shopList"
                                type="id"
                                style="max-width: 400px;margin-bottom: 20px;"
                />
                <custom-input
                        label="Name"
                        name="Name"
                        rules="required"
                        v-model="params.name"
                        :value="params.name"/>
                <custom-input
                        label="SearchName"
                        v-model="params.searchName"
                        :value="params.searchName"
                        rules="required"
                        name="SearchName"
                />
                <custom-input
                        label="Description"
                        v-model="params.description"
                        :value="params.description"
                        rules="required"
                        name="Description"
                />
                <br/>
                <div style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;">
                    <h3>Sku Info</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th v-for="(spec, specIndex) in params.skuSpecNames" :key="specIndex">{{ spec }}</th>
                                <th>Price</th>
                                <th>Sales Price</th>
                                <th>Stock</th>
                                <th>Price Unit</th>
                                <th>
                                    <span>Action</span>
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in params.skuList" :key="row.index" class="sku-row">
                                <td>{{ 'Sku ' + (index + 1) }}:</td>
                                <td v-for="(spec, specIndex) in row.specValues" :key="'spec-' + specIndex">
                                    <input :value="spec" readonly/>
                                </td>
                                <td>
                                    <input v-model="row.price" type="number"/>
                                </td>
                                <td>
                                    <input v-model="row.salesPrice" type="number"/>
                                </td>
                                <td>
                                    <input v-model="row.stock" type="number"/>
                                </td>
                                <td>
                                    <input v-model="row.priceUnit"/>
                                </td>
                                <td>
                                    <input type="checkbox"
                                           :id="'checkbox-' + row.skuId"
                                           :name="'checkbox-' + row.skuId"
                                           :checked="row.enabled"
                                           @change="handleSkuCheckboxChange($event, index)"
                                           style="display: flex;align-items: center;justify-content: center;cursor: pointer;size:20px;"
                                    />
                                </td>
                                <td>
                                    <div v-if="index === 0">
                                    <custom-button type="copy" color="primary" @click="handleCopyToAll">Copy to All</custom-button>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div v-if="checkParamsReady">
                    <custom-button :loading="loading" type="submit" color="primary" @click="handleSubmit">Submit</custom-button>
                </div>
                <!-- Submit Button -->
                <div v-else>
                    <custom-button
                            :loading="false"
                            color="primary"
                            class="white--text"
                            type="submit"
                            :disabled="true"
                    >Submit</custom-button>
                </div>
                <v-snackbar v-model="snackbar" top right color="success">
                    Success Post
                </v-snackbar>
                <v-snackbar v-model="snackbarFailed" top right color="error">
                    {{ errorMessage }}
                </v-snackbar>
                <div v-if="loading" class="loading-indicator">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderContent from "../../containers/HeaderContent";
    import {mapActions} from "vuex";
    import ShopSelect from '../Shop/Product/CustomSelect.vue';

    export default {
        components: {
            HeaderContent,
            ShopSelect,
        },
        methods: {
            ...mapActions({
                postGoods: "goods/updateStatus",
                updateProduct: "product/editProduct",
                searchProduct: "product/searchProduct",
                getListShop: "product/getListShop",
            }),
            handleGetListShop() {
                const payload = {
                    page: 0,
                    size: 200,
                    sort: `id,asc`
                };
                return this.getListShop(payload)
                    .then((res) => {
                        const data = res.data.data.content;
                        this.shopList.push(...data)
                    })
                    .catch((err) => {
                        console.log({err});
                    });
            },
            async handleSubmit() {
                console.info(this.params)
                this.loading = true
                let response  = await this.postGoods(this.params);
                if (response.status === 201 || response.status === 200) {
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push('/goods')
                        this.snackbar = false
                        this.loading = false
                    }, 1000)
                } else {
                    this.snackbarFailed = true
                    this.errorMessage = 'Failed Post'
                    setTimeout(() => {
                        this.snackbarFailed = false
                        this.loading = false
                    }, 2500)
                }
            },
            handleProductCheckboxChange(event, payload) {
                const isChecked = event.target.checked;

                if (!isChecked) {
                    this.params.productId = 0;
                    this.params.name = "";
                    this.params.searchName = "";
                    this.params.description = "";
                    this.params.categoryId = 0;
                    this.params.skuSpecNames = [];
                    this.params.skuList = [{
                        index: 1, specValues: [], skuId: 0, price: 0, stock: 0, salesPrice: 0, priceUnit: 'Rp', enabled: false
                    }];
                    this.selectedProductName = "";
                    this.coverImages = [];
                    this.params.mainIndex = null;
                } else {
                    this.params.productId = payload.productId;
                    this.params.name = payload.productName;
                    this.params.searchName = payload.productSearchName;
                    this.params.categoryId = payload.categoryId;
                    this.params.description = payload.productDesc;
                    this.params.skuSpecNames = payload.skuSpecNames;
                    this.params.skuList = payload.skuList;
                    this.selectedProductName = payload.productName;
                    this.coverImages = payload.productPhoto;
                }
            },
            handleSkuCheckboxChange(event, index) {
                const isChecked = event.target.checked;

                if (!isChecked) {
                    this.params.skuList[index].enabled =  false;
                } else {
                    this.params.skuList[index].enabled =  true;
                }

                this.checkParamsReadyMethod();
            },
            handleCopyToAll() {
                const firstSku = this.params.skuList[0];
                for (let i = 1; i < this.params.skuList.length; i++) {
                    this.params.skuList[i].price = firstSku.price;
                    this.params.skuList[i].salesPrice = firstSku.salesPrice;
                    this.params.skuList[i].stock = firstSku.stock;
                    this.params.skuList[i].priceUnit = firstSku.priceUnit;
                    this.params.skuList[i].enabled = firstSku.enabled;
                }
            },
            handleMainPhotoCheckboxChange(event, index) {
                const isChecked = event.target.checked;

                if (!isChecked) {
                    this.params.mainIndex =  null;
                } else {
                    this.params.mainIndex =  index;
                }

                this.checkParamsReadyMethod();
            },
            searchData() {
                console.log("Searching for:", this.searchQuery);
                if (this.searchQuery.trim() === '') {
                    return;
                }
                this.fetchSearchData(0);
            },
            async fetchSearchData (page) {
                this.isLoadingSearch = true;

                const payload = {
                    page: page,
                    size: 20,
                    sort: `createAt,desc`,
                    name: this.searchQuery
                };
                const response = await this.searchProduct(payload);
                if (response.status === 200) {
                    const responseData = response.data.data.content;
                    this.page = page;

                    const newOptions = responseData.map((item, index) => ({
                        index: index + 1,
                        name: item.name,
                        productId: item.id,
                        productName: item.name,
                        productSearchName: item.searchName,
                        categoryId: item.productCategoryId,
                        productDesc: item.description,
                        skuSpecNames: item.skuSpecs.map(spec => spec.name),
                        skuList: item.skuList.map((sku, idx) => ({
                            index: idx + 1,
                            specValues: sku.specs.map(spec => spec.value),
                            skuId: sku.id,
                            price: sku.price ? sku.price : 0,
                            stock: sku.stock ? sku.stock : 0,
                            salesPrice: sku.salesPrice ? sku.salesPrice : 0,
                            priceUnit: sku.priceUnit ? sku.priceUnit : 'Rp',
                        })),
                        productPhoto: item.medias.map(spec => spec.thumbnail.large),
                    }));
                    const oldOptions = this.productOptions;

                    const existingValues = new Set(newOptions.map(opt => opt.productId));
                    const filteredOptions = oldOptions.filter(opt => !existingValues.has(opt.productId));

                    this.productOptions = [
                        ...newOptions,
                        ...filteredOptions
                    ];

                    this.isLoadingSearch = false;
                } else {
                    this.isLoadingSearch = false;
                    return response;
                }
            },
            async fetchDataByLoadMore(page) {
                if(this.isLoadingProduct || this.isNoMoreProduct){
                    return ;
                }
                this.isLoadingProduct = true;

                const payload = {
                    page: page,
                    size: 20,
                    sort: `createAt,desc`,
                    name: this.searchQuery
                };
                const response = await this.searchProduct(payload);
                if (response.status === 200) {
                    const responseData = response.data.data.content;
                    this.page = page;
                    const newOptions = responseData.map((item, index) => ({
                        index: index + 1,
                        name: item.name,
                        productId: item.id,
                        productName: item.name,
                        productSearchName: item.searchName,
                        categoryId: item.productCategoryId,
                        productDesc: item.description,
                        skuSpecNames: item.skuSpecs.map(spec => spec.name),
                        skuList: item.skuList.map((sku, idx) => ({
                            index: idx + 1,
                            specValues: sku.specs.map(spec => spec.value),
                            skuId: sku.id,
                            price: sku.price ? sku.price : 0,
                            stock: sku.stock ? sku.stock : 0,
                            salesPrice: sku.salesPrice ? sku.salesPrice : 0,
                            priceUnit: sku.priceUnit ? sku.priceUnit : 'Rp',
                        })),
                        productPhoto: item.medias.map(spec => spec.thumbnail.large),
                    }));
                    if(responseData.length === 0){
                        this.isNoMoreProduct = true;
                    }

                    const existingValues = new Set(this.productOptions.map(opt => opt.productId));
                    const filteredOptions = newOptions.filter(opt => !existingValues.has(opt.productId));

                    this.productOptions = [
                        ...this.productOptions,
                        ...filteredOptions
                    ];

                    this.isLoadingProduct = false;
                } else {
                    this.isLoadingProduct = false;
                    return response;
                }
            },
            applySelectedProduct() {
                this.showProductDialog = false;
            },
            checkParamsReadyMethod() {
                const matchingSpec = this.params.skuList.find(item => item.enabled === true);
                this.params.enabled = !!matchingSpec;

                this.isAllReady = this.params.name !== '' &&
                    this.params.searchName !== '' &&
                    this.params.enabled === true &&
                    this.params.productId !== 0 &&
                    this.params.description !== '' &&
                    this.params.shopId !== 0 &&
                    this.params.skuSpecNames !== '' &&
                    this.params.skuSpecNames.length !== 0 &&
                    this.params.skuList !== '' &&
                    this.params.skuList.length !== 0;
            },
            openPopup(index) {
                this.currentImageIndex = index;
                this.isPopupOpen = true;
            },
            closePopup() {
                this.isPopupOpen = false;
            },
        },
        data() {
            return {
                items: [
                    {
                        text: "Manage Sales Goods",
                        disabled: false,
                        href: "/goods",
                    },
                ],
                params: {
                    id: null,
                    productId: 0,
                    name: "",
                    searchName: "",
                    description: "",
                    shopId: 0,
                    skuSpecNames: [],
                    skuList: [{
                        index: 1, specValues: [], skuId: 0, price: 0, stock: 0, salesPrice: 0, priceUnit: 'Rp', enabled: false
                    }],
                    createAt: "",
                    enabled: false,
                    categoryId: 0,
                    mainIndex: null
                },
                snackbar: false,
                loading: false,
                isLoading: false,
                snackbarFailed: false,
                errorMessage: 'Failed Post',
                images: [],
                shopList: [],
                skuSpecList: [],
                coverOptionList: [],
                video: "",
                isNoImage: false,
                isAllReady: false,
                showProductDialog: false,
                searchQuery: '',
                productOptions: [],
                selectedProductName: "",
                coverImages: [],
                isLoadingSearch: false,
                selectedAll: false,
                isLoadingProduct: false,
                isNoMoreProduct: false,
                errorMessageImage: '',
                currentImageIndex: null,
                isPopupOpen: false,
                isCoverPopupOpen: false,
                isRequiredSpecImage: false,
                popupOpenType: "",
            };
        },
        created() {
            let { id, name, searchName, description, skuSpecNames, skuList, categoryId, createAt, medias } = this.$route.params;

            this.params = {
                id: null,
                productId: id ? id : 0,
                name: name ? name : "",
                searchName: searchName ? searchName : "",
                description: description ? description : "",
                shopId: 0,
                skuSpecNames: skuSpecNames ? JSON.parse(skuSpecNames) : [],
                skuList: skuList ? JSON.parse(skuList).map(item => ({
                    index: item.index || 1,
                    specValues: item.specValues || [],
                    skuId: item.skuId || 0,
                    price: item.price || 0,
                    stock: item.stock || 0,
                    salesPrice: item.salesPrice || 0,
                    priceUnit: item.priceUnit || 'Rp',
                    enabled: item.enabled || false
                })) : [{
                    index: 1, specValues: [], skuId: 0, price: 0, stock: 0, salesPrice: 0, priceUnit: 'Rp', enabled: false
                }],
                createAt: createAt ? createAt : "",
                enabled: false,
                categoryId: categoryId ? categoryId : 0,
                mainIndex: null
            };
            this.selectedProductName = name ? name : "";
            this.coverImages = medias ? JSON.parse(medias).map(spec => spec.thumbnail.large) : [];
        },
        mounted() {
            this.fetchSearchData(0);
            this.handleGetListShop();
        },
        watch: {
            'params.shopId'(newValue, oldValue) {
                this.checkParamsReadyMethod();
            }
        },
        computed: {
            checkParamsReady() {
                return this.isAllReady;
            },
            filteredProduct() {
                if(this.searchQuery === '') {
                    return this.productOptions;
                }else {
                    const query = this.searchQuery.toLowerCase();
                    return this.productOptions.filter(product =>
                        product.productName.toLowerCase().includes(query) || product.productSearchName.toLowerCase().includes(query)
                    );
                }
            },
        }
    };
</script>

<style lang="scss" scoped>
    .__header {
        text-align: left;
        margin-bottom: 8px;
    }

    .left-panel {
        width: 1500px;
        padding: 20px;
        overflow-y: auto;
    }

    .left-panel ul {
        list-style-type: none;
        padding: 0;
    }

    .left-panel li {
        margin: 5px 0;
    }

    .right-panel {
        width: 400px;
        padding: 20px;
    }

    .__image-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 8px;
        width: 100%;
    }

    .__image-wrapper {
        position: relative;
        width: 80px;
        height: 120px;
        border-radius: 5px;
    }

    .remove-Media-btn {
        color: #FFFFFF;
        width: 13px;
        height: 13px;
        border-radius: 2px;
        background: #0000004D;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .remove-media-icon {
        font-size: 8px !important;
        color: white !important;
    }


    .__image-box {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px dashed #CECECE;
        cursor: pointer;
        border-radius: 5px;
        margin: 5px;
    }

    .__image-placeholder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #ccc;
        font-size: 24px;
    }

    .__cover-placeholder {
        border: 0.5px dashed #CECECE;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 40px;
        color: #ccc;
        font-size: 24px;
    }

    .__image {
        width: 100%;
        height: 100px;
        overflow: hidden;
    }

    .__image-no {
        width: 100%;
        height: 100%;
        background-color: #EEEEEE;
        border-radius: 5px;
    }

    .__box {
        width: 400px;
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .popup-content {
        position: relative;
        width: 300px;
        height: 424px;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background: #f00;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .__text-alert {
        font-size: 12px;
        color: red;
    }

    .upload-error {
        font-size: 11px;
        font-weight: 400;
        color: $warning;
        padding: 0 20px;
    }

    .table-container {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: none;
    }

    table th, table td {
        padding: 8px;
        text-align: left;
    }

    table input {
        width: 100%;
        border: 1px solid #ccc;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    table select {
        width: 100%; /* 使 select 元素填充单元格宽度 */
        height: 30px; /* 设置高度 */
        border: 1px solid #ccc; /* 边框样式 */
        border-radius: 8px; /* 圆角边框 */
        background-color: #fff; /* 背景颜色 */
        padding: 0 10px; /* 内边距 */
        box-sizing: border-box; /* 包含内边距和边框在宽度和高度中 */
        font-size: 14px; /* 字体大小 */
        color: #333; /* 字体颜色 */
        cursor: pointer; /* 鼠标悬停时显示手指光标 */
    }

    .table-button {
        background-color: #F4F4F4;
        border: none;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 4px;
        padding: 6px 10px;
        transition: background-color 0.3s ease;
    }

    .circle-button {
        color: #ff0000; /* '+' 号的颜色为红色 */
        border: none; /* 移除默认边框 */
        border-radius: 50%; /* 圆形按钮 */
        width: 30px; /* 按钮的宽度 */
        height: 30px; /* 按钮的高度 */
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        font-size: 20px; /* '+' 号的字体大小 */
        cursor: pointer; /* 鼠标悬停时显示手指光标 */
    }

    .footer-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .footer-actions button {
        width: 94px;
        height: 30px;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }

    .footer-actions button:first-of-type {
        background-color: #F4F4F4;
        color: #333;
    }

    .footer-actions button:last-of-type {
        background-color: #FF4265;
    }

    .selected-spec {
        display: flex;
        flex-wrap: wrap;
        border: 0.5px solid #CECECE;
        border-radius: 8px;
    }

    .spec-module {
        display: flex;
        align-items: center;
        background-color: #FFF3F5;
        border-radius: 16px;
        padding: 2px 2px;
        margin: 5px;
        border: 0.2px solid #FF617E;
    }

    .remove-btn {
        width: 5px;
        height: 5px;
        border-radius: 2px;
        background-color: #FFF3F5;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .remove-icon {
        font-size: 13px !important;
        color: #8B8B8B !important;
    }

    .editable-div {
        min-width: 100px;
        padding: 5px;
        border: 1px solid #CECECE;
        border-radius: 8px;
        font-size: 12px;
        margin-top: 5px;
    }

    .loading-indicator {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* 确保在最上层 */
    }

    .spinner {
        border: 8px solid #f3f3f3; /* 边框颜色 */
        border-top: 8px solid red; /* 上边框红色 */
        border-radius: 50%;
        width: 50px; /* 宽度 */
        height: 50px; /* 高度 */
        animation: spin 1s linear infinite; /* 旋转动画 */
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

.account-row:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}
.sku-row:hover {
    background-color: #f0f0f0;
}

</style>
