<template>
    <custom-form :onSubmit="handleSubmit">
        <div style="display: flex;flex: 1;">
            <div class="left-panel">
                <div class="form__section mt-6">
                    <upload-oss
                            id="adPhoto"
                            style="display: none"
                            @response="getResponse"
                            :typeAllowed="['jpeg', 'png', 'jpg', 'gif']"
                            :maxPhotoSize="600000"
                    />
                    <div class="form__header">
                        <span class="form__header-text">
                            <span class="form__required">*</span>
                            <b>Activity Cover Photo</b>
                            <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">  Photo(1)  </span></span>
                    </div>
                    <div class="form__photo-upload-box" v-if="selectedOption === 'photo'">
                        <div style="display: flex;flex-direction: column;align-items: flex-start"
                             v-if="ad.medias && ad.medias.length && ad.medias[0] && ad.medias[0].type === 'image'"
                             v-for="(item, idx) in ad.medias" :key="idx">
                            <div style="display: flex;align-items: center;">
                                <div
                                        class="form__image-box"
                                        :class="{ 'form__image-error': isNoImage }"
                                >
                                    <div class="form__image-list">
                                        <div class="form__image-wrapper">
                                            <img :src="imageUrl(idx)" class="form__image" @click="openPopup(idx)"/>
                                            <div @click.stop="removeMedia(idx)" class="remove-Media-btn">
                                                <v-icon class="remove-media-icon">mdi-close</v-icon>
                                            </div>
                                        </div>
                                        <div v-if="isPopupOpen" class="popup" @click="closePopup">
                                            <div class="popup-content"
                                                 v-if="ad.medias && ad.medias[0] && ad.medias[0].type === 'image'">
                                                <!-- Close button -->
                                                <button class="close-btn" @click="closePopup">X</button>
                                                <!-- Image inside the popup -->
                                                <img :src="imageUrl(currentImageIndex)" class="popup-image"/>
                                            </div>
                                            <div v-else class="popup-content">
                                                <!-- Image inside the popup -->
                                                <video controls class="popup-image" @click.stop>
                                                    <source :src="ad.medias[idx].url" type="video/mp4"/>
                                                </video>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;align-items: center;" v-if="(ad.medias && ad.medias.length === 0) || (ad.medias &&
                        ad.medias.length < 1 && ad.medias[0] && ad.medias[0].type && ad.medias[0].type === 'image')">
                            <div
                                    class="form__image-box"
                                    :class="{ 'form__image-error': isNoImage }"
                                    @click="handleUpload('adPhoto', 'noCover')"
                            >
                                <div class="form__image-placeholder">
                                    <v-icon>mdi-plus</v-icon> <!-- Use mdi-plus instead of $addplus -->
                                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: left;">
                                        Cover Photo
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="isLoading" class="form__loading">
                            <v-progress-circular :indeterminate="true" color="primary" size="50"></v-progress-circular>
                        </div>
                        <span v-show="isNoImage" class="form__text-alert error--text">
                            The Image field is required
                        </span>
                        <div v-if="errorMessageImage" class="upload-error">
                            {{ errorMessageImage }}
                        </div>
                    </div>
                </div>
                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__required">*</span>
                        <span class="form__header-text"><b>Type</b></span>
                    </div>
                    <custom-select
                            v-model="selectedType"
                            :items="typeOptions"
                            item-text="text"
                            item-value="value"
                            name="SelectedType"
                            placeholder="Select Type Options"
                            class="form__select"
                    />
                </div>
                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__required">*</span>
                        <span class="form__header-text"><b>Title</b></span>
                    </div>
                    <custom-input
                            v-model="ad.title"
                            :value="ad.title"
                            name="Title"
                            placeholder="Enter activity title"
                            class="form__input"
                            :maxlength="80"
                            rules="required"
                            suffix="80"
                    />
                </div>
                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__header-text"><b>Description</b></span>
                    </div>
                    <custom-textarea
                            v-model="ad.description"
                            :value="ad.description"
                            name="Description"
                            placeholder="Enter activity description"
                            class="form__input"
                            :maxlength="500"
                    />
                </div>

                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__required">*</span>
                        <span class="form__header-text"><b>Start Time</b></span>
                    </div>
                    <custom-input
                            v-model="ad.startTime"
                            :value="ad.startTime"
                            rules="required"
                            name="StartTime"
                            placeholder="Enter activity start time"
                            class="form__input"
                            @click="showStartDateDialog = true"
                    />
                    <v-dialog v-model="showStartDateDialog" max-width="500px" style="z-index: 1">
                        <v-date-picker
                                width="450"
                                v-model="ad.startTime"
                                no-title
                                color="primary"
                                :max="ad.endTime"
                        >
                        </v-date-picker>
                        <custom-button color="primary" @click="closeDialog">Confirm</custom-button>
                    </v-dialog>
                </div>

                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__required">*</span>
                        <span class="form__header-text"><b>End Time</b></span>
                    </div>
                    <custom-input
                            v-model="ad.endTime"
                            :value="ad.endTime"
                            rules="required"
                            name="EndTime"
                            placeholder="Enter activity end time"
                            class="form__input"
                            @click="showEndDateDialog = true"
                    />
                    <v-dialog v-model="showEndDateDialog" max-width="500px" style="z-index: 1">
                        <v-date-picker
                                width="450"
                                v-model="ad.endTime"
                                no-title
                                color="primary"
                                :min="ad.startTime"
                        >
                        </v-date-picker>
                        <custom-button color="primary" @click="closeDialog">Confirm</custom-button>
                    </v-dialog>
                </div>

                <div class="form__section mt-6">
                    <div class="form__header">
                        <span class="form__required">*</span>
                        <span class="form__header-text"><b>Specify Goods</b></span>
                    </div>
                    <div class="form__selectAccount">
                        <div @click="showGoodsDialog = true" class="selected-accounts">
                            <div
                                    v-for="(text, value) in selectedGoodsText"
                                    :key="value"
                                    class="account-module"
                            >
                                <span style="font-size: 12px">{{ text }}</span>
                                <v-btn
                                        icon
                                        @click.stop="removeGoods(value)"
                                        class="remove-btn"
                                        style="width: 16px; height: 16px; border-radius: 50%; background-color: #FFF3F5; padding: 0;"
                                >
                                    <v-icon class="remove-icon">mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div v-if="ad.accountType !== 'ALL' && ad.accountIds.length === 0"
                             @click="showGoodsDialog = true">
                            <custom-select placeholder="Select specify goods"></custom-select>
                        </div>
                    </div>
                    <v-dialog v-model="showGoodsDialog" max-width="600px" style="z-index: 1">
                        <v-card>
                            <v-card-title>
                                <v-text-field
                                        v-model="searchQuery"
                                        label="Search Goods"
                                        prepend-icon="mdi-magnify"
                                        @keydown.enter="searchData"
                                        :loading="isLoadingSearch"
                                />
                                <v-progress-linear
                                        indeterminate
                                        class="mt-3"
                                        v-if="isLoadingSearch"
                                ></v-progress-linear>
                            </v-card-title>
                            <v-card-text>
                                <v-list>
                                    <div>
                                        <div class="account-row"
                                             style="display: flex;align-items: center; padding: 13px;">
                                            <img src="@/assets/all-account.jpg"
                                                 style="width: 15px;height: 15px;border-radius: 50%;margin-right: 16px;"
                                                 alt=""/>
                                            <div>
                                                <span>All Seleb Account</span>
                                            </div>
                                            <div style="margin-left: auto;width: 9px;height: 9px;margin-right: 16px;justify-content: center;align-items: center;">
                                                <input type="checkbox" id="allhorns" name="allhorns"
                                                       :checked="ad.accountType === 'ALL'"
                                                       @change="handleSpecialCheckboxChange($event)"/>
                                            </div>
                                        </div>
                                        <div
                                                v-for="account in filteredAccounts"
                                                :key="account.value"
                                                class="account-row"
                                                style="display: flex;align-items: center; padding: 13px;"
                                        >
                                            <img :src="account.photo"
                                                 style="width: 15px;height: 15px;border-radius: 50%;margin-right: 16px;"
                                                 alt=""/>
                                            <div>
                                                <span>{{ account.text }}</span>
                                            </div>
                                            <div style="margin-left: auto;width: 9px;height: 9px;margin-right: 16px;justify-content: center;align-items: center;">
                                                <input type="checkbox" id="horns" name="horns" :value="account.value"
                                                       :checked="ad.accountIds.includes(account.value)"
                                                       @change="handleCheckboxChange($event, account.value)"/>
                                            </div>
                                        </div>
                                        <div style="display: flex;align-items: center;justify-content: center;margin-top: 16px;">
                                            <button @click="getByAccountType(page + 1)">
                                                <span v-if="isLoadingAccount">loading...</span>
                                                <span v-else>load more...</span>
                                            </button>
                                            <div v-if="isLoadingAccount" style="margin-left: 10px;">
                                                <i class="fas fa-spinner fa-spin"></i>
                                            </div>
                                        </div>
                                    </div>
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="white--text red" @click="applySelectedAccounts">Apply</v-btn>
                                <v-btn text @click="showGoodsDialog = false">Cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>


                <!-- Submit Button -->
                <div v-if="checkParamsReady">
                    <custom-button
                            :loading="loading"
                            color="primary"
                            class="white--text"
                            type="submit"
                    >{{ labelButton }}
                    </custom-button>
                </div>
                <!-- Submit Button -->
                <div v-else>
                    <custom-button
                            :loading="false"
                            color="primary"
                            class="white--text"
                            type="submit"
                            :disabled="true"
                    >{{ labelButton }}
                    </custom-button>
                </div>
            </div>
        </div>
    </custom-form>
</template>


<script>
    import {mapActions} from "vuex";

    export default {
        props: {
            ad: {
                type: Object,
            },
            labelButton: {
                type: String,
            },
            loading: {
                type: Boolean,
            },
            status: String,
        },
        computed: {
            selectedGoodsText() {
                if (this.ad.accountType === "ALL") {
                    const result = [];
                    result[0] = "All Seleb Account";
                    return result;
                } else {
                    return this.accountOptions.reduce((result, account) => {
                        if (this.ad.accountIds.includes(account.value)) {
                            result[account.value] = account.text;
                        }
                        return result;
                    }, {});
                }
            },
            filteredAccounts() {
                if (this.searchQuery === '') {
                    return this.accountOptions;
                } else {
                    const query = this.searchQuery.toLowerCase();
                    return this.accountOptions.filter(account =>
                        account.text.toLowerCase().includes(query)
                    );
                }
            },
            selectedType: {
                get() {
                    if (!(this.ad.isOfficial) || this.ad.isOfficial === '') {
                        this.ad.isOfficial = 'true';
                    }
                    return this.ad.isOfficial;
                },
                set(value) {
                    this.ad.isOfficial = value;
                },
            },
            selectedFeed: {
                get() {
                    return this.ad.adCampaignType;
                },
                set(value) {
                    this.ad.adCampaignType = value;
                },
            },
            selectedAccount: {
                get() {
                    return this.ad.accountIds;
                },
                set(value) {
                    console.info("accountIds:" + value)
                    this.ad.accountIds.push(value);
                },
            },
            checkParamsReady() {
                return this.ad.duration !== '' &&
                    ((this.ad.accountType === 'SPECIFIC' &&
                        this.ad.accountIds !== '' &&
                        this.ad.accountIds.length !== 0) ||
                        (this.ad.accountType === 'ALL')) &&
                    this.ad.adCampaignType !== '' &&
                    this.ad.isOfficial !== '' &&
                    this.ad.medias !== '' &&
                    this.ad.medias.length !== 0 &&
                    this.coverUrl !== '' &&
                    this.ad.adStatus !== 3 &&
                    this.ad.adStatus !== 2;
            },
        },
        watch: {
            // selectedOption(newValue) {
            //   this.ad.medias = [];
            // },
        },
        data() {
            return {
                accountOptions: [],
                feedOptions: [
                    {value: 0, text: 'All video'},
                    {value: 1, text: 'The latest video'},
                    {value: 2, text: 'The latest two videos'},
                    {value: 3, text: 'The latest three videos'},
                ],
                typeOptions: [
                    {value: '1', text: 'Activity'},
                    {value: '2', text: 'Voucher'},
                ],
                selectedOption: 'photo',
                page: 0,
                showGoodsDialog: false,
                showStartDateDialog: false,
                showEndDateDialog: false,
                selectedDate: null,
                currentImageIndex: null,
                searchQuery: '',
                isNoImage: false,
                isLoading: false,
                isLoadingAccount: false,
                isLoadingSearch: false,
                isLowResolution: false,
                isPopupOpen: false,
                isCoverPhoto: false,
                coverUrl: '',
                isCoverPopupOpen: false,
                errorMessageImage: '',
            };
        },
        async mounted() {
            if (this.$route.params.accountIds) {
                const accountIdsStr = this.$route.params.accountIds;
                const accountIds = JSON.parse(accountIdsStr);

                for (const id of accountIds) {
                    await this.handleResponseById(id);
                }
            }
            if (this.$route.params.medias) {
                const mediasStr = this.$route.params.medias;
                const medias = JSON.parse(mediasStr);

                this.coverUrl = medias[0].thumbnail.small;

                if (medias[0].type === 'image') {
                    this.selectedOption = 'photo';
                } else {
                    this.selectedOption = 'video';
                }
            }
            await this.getByAccountType(0);
        },
        methods: {
            ...mapActions({
                getAccountById: "account/getAccountById",
                getListByType: "account/getListByAccountType",
                searchAccount: "account/searchAccount",
            }),
            trimInput(index) {
                this.ad.medias[index].target = this.ad.medias[index].target.trim();
            },
            imageUrl(index) {
                if (this.ad.medias && this.ad.medias[index] && this.ad.medias[index].type === 'image') {
                    return this.ad.medias[index].url;
                } else if (this.ad.medias && this.ad.medias[index] && this.ad.medias[index].thumbnail && this.ad.medias[index].thumbnail.large) {
                    return this.ad.medias[index].thumbnail.large;
                } else {
                    return ''
                }
            },
            searchData() {
                console.log("Searching for:", this.searchQuery);
                if (this.searchQuery.trim() === '') {
                    return;
                }
                this.fetchSearchData(0);
            },
            handleUpload(id, type) {
                if (type === 'isCover') {
                    this.isCoverPhoto = true;
                }
                document.getElementById(id).click();
            },
            handleSubmit() {
                if (this.ad.medias) {
                    if (this.ad.medias[0].type === 'image') {
                        this.ad.medias.forEach(media => {
                            media.thumbnail.large = this.coverUrl;
                            media.thumbnail.medium = this.coverUrl;
                            media.thumbnail.small = this.coverUrl;
                        });
                    } else {
                        this.ad.medias.forEach(media => {
                            media.thumbnail.medium = this.coverUrl;
                            media.thumbnail.small = this.coverUrl;
                        });
                    }
                    this.$emit("onSubmit", this.ad);
                } else {
                    this.isNoImage = true;
                }
            },
            getResponse(payload) {
                if (payload.status === "loading") {
                    this.isLoading = true;
                    this.isNoImage = false;
                } else if (payload.status === "success") {
                    const data = {
                        ...payload,
                    };

                    if (this.isCoverPhoto) {
                        this.coverUrl = data.url
                        if (this.ad.medias && this.ad.medias.length > 0) {
                            this.ad.medias.forEach(media => {
                                media.id = '';
                            });
                        }
                    } else {
                        this.ad.medias.push(data);
                    }
                    this.isLoading = false;
                    this.isNoImage = false;
                    this.isCoverPhoto = false;
                    this.errorMessageImage = "";
                } else if (payload.status === "failed") {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = payload.message;
                } else {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = "";
                }
            },
            async fetchSearchData(page) {
                this.isLoadingSearch = true;

                const payload = {
                    type: 'users',
                    params: this.searchQuery,
                    data: {
                        page: page
                    }
                }
                const response = await this.searchAccount(payload);
                if (response.status === 200) {
                    const responseData = response.data.data.content;
                    this.page = page;
                    const newOptions = responseData.map(item => ({
                        value: item.id,
                        text: item.name,
                        photo: item.photo
                    }));
                    const oldOptions = this.accountOptions;

                    const existingValues = new Set(newOptions.map(opt => opt.value));
                    const filteredOptions = oldOptions.filter(opt => !existingValues.has(opt.value));

                    this.accountOptions = [
                        ...newOptions,
                        ...filteredOptions
                    ];

                    this.isLoadingSearch = false;
                } else {
                    this.isLoadingSearch = false;
                    return response;
                }
            },
            async getByAccountType(page) {
                if (this.isLoadingAccount) {
                    return;
                }
                this.isLoadingAccount = true;

                const data = {
                    param: {
                        accountType: "SELEB",
                        page: page,
                        size: 20
                    },
                };
                const response = await this.getListByType(data);
                if (response.status === 200) {
                    const responseData = response.data.data.content;
                    this.page = page;
                    const newOptions = responseData.map(item => ({
                        value: item.id,
                        text: item.name,
                        photo: item.photo
                    }));

                    const existingValues = new Set(this.accountOptions.map(opt => opt.value));
                    const filteredOptions = newOptions.filter(opt => !existingValues.has(opt.value));

                    this.accountOptions = [
                        ...this.accountOptions,
                        ...filteredOptions
                    ];

                    this.isLoadingAccount = false;
                } else {
                    this.isLoadingAccount = false;
                    return response;
                }
            },
            async handleResponseById(id) {
                const params = {
                    id: id,
                    type: "users",
                };
                const response = await this.getAccountById(params);
                if (response.status === 200) {
                    const responseData = response.data.data;

                    const newOptions = {
                        value: responseData.id,
                        text: responseData.name,
                        photo: responseData.photo
                    };
                    const newOptionsArr = [newOptions];

                    const existingValues = new Set(this.accountOptions.map(opt => opt.value));
                    const filteredOptions = newOptionsArr.filter(opt => !existingValues.has(opt.value));

                    this.accountOptions = [
                        ...filteredOptions,
                        ...this.accountOptions
                    ];
                }
            },
            closeDialog() {
                this.showGoodsDialog = false;
                this.showStartDateDialog = false;
                this.showEndDateDialog = false;
            },
            applySelectedAccounts() {
                this.showGoodsDialog = false;
            },
            removeMedia(index) {
                this.ad.medias.splice(index, 1); // Remove image by index
            },
            removeCover() {
                this.coverUrl = ''; // Remove image by index
            },
            handleSpecialCheckboxChange(event) {
                const isChecked = event.target.checked;

                if (isChecked) {
                    this.ad.accountIds = [];
                    this.ad.accountType = 'ALL';
                } else {
                    this.ad.accountType = 'SPECIFIC';
                }
            },
            handleCheckboxChange(event, value) {
                const isChecked = event.target.checked;

                if (isChecked) {
                    if (!this.ad.accountIds.includes(value)) {
                        this.ad.accountIds.push(value);
                    }
                    this.ad.accountType = 'SPECIFIC';
                } else {
                    const index = this.ad.accountIds.indexOf(value);
                    if (index > -1) {
                        this.ad.accountIds.splice(index, 1);
                    }
                }
            },
            removeGoods(value) {
                if (this.ad.accountType === "ALL") {
                    this.ad.accountType = '';
                } else {
                    const index = this.ad.accountIds.indexOf(value);
                    if (index > -1) {
                        this.ad.accountIds.splice(index, 1);
                    }
                }
            },
            openPopup(index) {
                this.currentImageIndex = index;
                this.isPopupOpen = true;
            },
            closePopup() {
                this.isPopupOpen = false;
            },
            openCoverPopup() {
                this.isCoverPopupOpen = true;
            },
            closeCoverPopup() {
                this.isCoverPopupOpen = false;
            },
        },
    };
</script>

<style lang="sass" scoped>
.form
    &__header
      text-align: left
      margin-bottom: 8px // Adjust space between header and input

    &__header-text
      font-size: 1rem // Font size for headers
      color: black

    &__required
      color: red
      margin-right: 3px // Space between the red asterisk and the text

    &__upload-box
      display: flex
      flex-direction: row
      align-items: flex-start
      width: 100%

    &__photo-upload-box
      display: flex
      flex-direction: column
      align-items: flex-start
      width: 100%

    &__image-box
      position: relative
      width: 80px // Adjust size as needed
      height: 80px // Adjust size as needed
      display: flex
      justify-content: center
      align-items: center
      border: 0.5px dashed #CECECE
      cursor: pointer
      border-radius: 5px
      margin-right: 5px
      margin-bottom: 5px

    &__image-list
      display: flex
      flex-wrap: nowrap
      overflow-x: auto
      gap: 8px
      width: 100%

    &__image-wrapper
      position: relative
      width: 80px // Adjust size as needed
      height: 80px // Adjust size as needed
      border-radius: 5px

    &__image
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 5px

    &__text-alert
      font-size: 12px
      color: red

    &__image-placeholder
      display: flex
      justify-content: center
      flex-direction: column
      align-items: center
      width: 100%
      height: 100%
      color: #ccc
      font-size: 24px // Adjust size of the icon as needed

    &__section
      margin-bottom: 16px // Adds space between sections

    &__input, &__select
      font-size: 1.5rem // Match the font size of headers
      color: black
      max-width: 50vw // Use max-width to ensure it doesn't exceed half of the viewport width
      width: 100%
      margin: 0 auto // Center align the input/select if needed

    &__platform-input
      font-size: 16px // Match the font size of headers
      color: black
      max-width: 180px // Use max-width to ensure it doesn't exceed half of the viewport width
      width: 180px
      margin-left: 20px
      height: 35px
      border: 0.2px solid #CECECE
      border-radius: 5px

.left-panel
        width: 1400px
        padding: 20px
        overflow-y: auto


.selected-accounts
  display: flex
  flex-wrap: wrap
  border: 0.5px solid #CECECE
  border-radius: 8px

.account-module
  display: flex
  align-items: center
  background-color: #FFF3F5
  border-radius: 16px
  padding: 2px 2px
  margin: 5px
  border: 0.2px solid #FF617E


.remove-Media-btn
  color: #FFFFFF
  width: 13px
  height: 13px
  border-radius: 2px
  background: #0000004D
  border: none
  position: absolute
  top: 0
  right: 0
  display: flex
  align-items: center
  justify-content: center

.remove-media-icon
  font-size: 8px !important
  color: white !important


.account-module span
  margin-right: 3px

.char-count
    font-size: 12px
    color: #666
    text-align: right

.duration-label
    font-size: 1rem
    margin-left: 8px
    color: black

.remove-btn
    width: 5px
    height: 5px
    border-radius: 2px
    background-color: #FFF3F5
    border: none
    color: white
    display: flex
    align-items: center
    justify-content: center
    padding: 0

.remove-icon
  font-size: 13px !important
  color: #8B8B8B !important

.account-row:hover
  background-color: #f0f0f0
  cursor: pointer

.form__loading
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 9999

.popup
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.5)
  display: flex
  justify-content: center
  align-items: center
  z-index: 9999

.popup-content
  position: relative
  width: 300px
  height: 424px
  background: #fff
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
  display: flex
  justify-content: center
  align-items: center

.popup-image
  max-width: 100%
  max-height: 100%
  object-fit: cover

/* Close button styling */
.close-btn
  position: absolute
  top: 10px
  right: 10px
  border: none
  background: #f00
  color: #fff
  font-size: 18px
  cursor: pointer
  border-radius: 50%
  width: 30px
  height: 30px
  display: flex
  justify-content: center
  align-items: center

.upload-error
  font-size: 11px
  font-weight: 400
  color: $warning
  padding: 0 20px

</style>

