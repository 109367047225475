<template>
  <div>
    <HeaderContent label="Order List" :list="crumbs" />

    <div class="filter__card">
      <!-- 订单编号查询输入框 -->
      <div style="width: 200px">
        <k-input
            :disabled="false"
            placeholder="Order Code"
            v-model="orderCodeKeyword"
            @blur="inputOrderCodeKeyword"
        ></k-input>
      </div>
      <!-- 订单状态下拉框 -->
      <div style="width: 200px">
        <k-select
            :disabled="false"
            placeholder="Select order status"
            v-model="orderStatusKeyword"
            @input="inputOrderStatus"
            :items="orderStatusOptions"
        ></k-select>
      </div>
      <!-- 查询按钮 -->
      <custom-button
          color="kellygreen"
          class="white--text"
          size="x-medium"
          @click="searchOrderList"
      >
        Query
      </custom-button>
    </div>

    <v-data-table
        :headers="tableHeaders"
        :items="orderList"
        disable-filtering
        disable-sort
        hide-default-footer
    >
      <template v-slot:[`item.creatTimestamp`]="{ item }">
        <span>{{ formatTimestamp(item.creatTimestamp) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click="openOrderDetail(item.id)">
          <v-icon x-small color="primary">mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
        class="d-flex justify-end"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-model="page"
        :length="totalPages"
        @input="handleGetOrderList"
        :total-visible="6"
    ></v-pagination>

    <!-- 订单详情弹框 -->
    <OrderDetail
      :orderDetailDiglog="orderDetailDiglog"
      :orderDetailItems="orderDetailItems"
      @closeOrderDetail="closeOrderDetail"
    ></OrderDetail>
  </div>
</template>

<script>
import HeaderContent from "@/containers/HeaderContent/index.vue";
import OrderDetail from "./orderDetail.vue";
import {mapActions} from "vuex";

export default {
  components: {
    HeaderContent,
    OrderDetail,
  },
  data() {
    return {
      crumbs: [
        {
          text: "Order",
        },
        {
          text: "List Order",
          disabled: true,
        },
      ],
      orderCodeKeyword: '',
      orderStatusKeyword: {},
      orderStatusOptions: [
        { label: 'All', value: '0' },
        { label: 'ORDER_CREATED', value: '1' },
        { label: 'PENDING_PAYMENT', value: '2' },
        { label: 'PAID', value: '3' },
        { label: 'MERCAHT_CONFIRMED', value: '4' },
        { label: 'PENDING_SHIPMENT', value: '5' },
        { label: 'IN_TRANSIT', value: '6' },
        { label: 'CANCELLED', value: '7' },
        { label: 'DELIVERED', value: '8' },
        { label: 'COMPLETED', value: '9' },
        { label: 'REFUNDED', value: '10' },
        { label: 'REFUND_PROCESSING', value: '11' },
      ],
      tableHeaders: [
        {
          text: '订单编号',
          value: 'orderCode',
          width: "100",
          class: "whitesnow",
        },{
          text: '商品名称',
          value: 'orderProduct.productName',
          width: "100",
          class: "whitesnow",
        },{
          text: '订单状态',
          value: 'orderStatus',
          width: "100",
          class: "whitesnow",
        },{
          text: '下单时间',
          value: 'creatTimestamp',
          width: "100",
          class: "whitesnow",
        },{
          text: '下单用户',
          value: 'orderCustomer.name',
          width: "100",
          class: "whitesnow",
        },{
          value: "action",
          class: "whitesnow",
          width: "100",
        }
      ],
      orderList: [],
      orderDetailDiglog: false,
      orderDetailItems: null,
      orderListParams:{
        orderCode: null,
        userName: null,
        goodsName: null,
        orderStatus: null,
        creatStartTimestamp: null,
        creatEndTimestamp: null,
        pageSize: 10,
        pageNum: 1,
      },
      totalPages: 0,
      page: 1,
    };
  },
  created() {
    this.handleGetOrderList();
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    ...mapActions({
      getOrderList: "order/getOrderList",
      getOrderDetailById: "order/getOrderDetailById",
    }),
    inputOrderCodeKeyword(orderCode){
      this.orderListParams.orderCode = this.orderCodeKeyword;
    },
    inputOrderStatus(){
      console.log("选择订单状态",this.orderStatusKeyword);
    },
    async handleGetOrderList(p){
      this.orderListParams.pageNum = p || 1;
      const response = await this.getOrderList(this.orderListParams);
      if(response.status === 200){
        this.orderList = response.data.data.content;
        this.totalPages = Math.ceil(response.data.data.totalElements / this.orderListParams.pageSize);
      }
    },
    searchOrderList(){
      this.orderListParams.pageNum = 1;
      this.orderListParams.pageSize = 10;
      this.handleGetOrderList();
      this.page = 1;
    },
    async searchOrderDetailById(id){
      const response = await this.getOrderDetailById(id);
      if(response.status === 200){
        this.orderDetailItems = response.data.data;
      }
    },
    openOrderDetail(id){
      this.searchOrderDetailById(id);
      this.orderDetailDiglog = true;
    },
    closeOrderDetail(){
      this.orderDetailDiglog = false;
      this.orderDetailItems = null;
    },
    formatTimestamp(timestamp){
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
}
</script>