<template>
    <div>
        <HeaderContent :list="items" label="Post Product"/>
        <div style="display: flex;flex: 1;">
            <div class="left-panel">
                <h3>Product Info</h3>
                <div class="align-center">
                    <upload-oss
                            id="pd"
                            style="display: none"
                            @response="getResponse"
                            :typeAllowed="['jpeg', 'png', 'jpg', 'gif', 'mp4', 'avi']"
                            :maxPhotoSize="600000"
                            :videoDuration="15"
                            :limitResolution="540"
                    />
                    <div class="__header">
                        <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Photo(6) - Video(1)</span>
                    </div>
                    <div class="__image-list" v-if="params.medias && params.medias.length > 0">
                        <div v-for="(item, idx) in params.medias" :key="idx">
                            <div style="position: relative;width: 80px;height: 80px;border-radius: 5px;"
                                 v-if="item.type === 'video'" @click="openPopup(idx, 'video')">
                                <video
                                        :src="item.url"
                                />
                                <div @click.stop="removeMedia(idx)" class="remove-Media-btn">
                                    <v-icon class="remove-media-icon">mdi-close</v-icon>
                                </div>
                            </div>
                            <div v-if="item.type === 'image'" class="__image-wrapper">
                                <img :src="mediaUrl(idx)" class="__image" @click="openPopup(idx, 'image')"/>
                                <div @click.stop="removeMedia(idx)" class="remove-Media-btn">
                                    <v-icon class="remove-media-icon">mdi-close</v-icon>
                                </div>
                            </div>
                            <div v-if="isPopupOpen" class="popup" @click="closePopup">
                                <div class="popup-content" v-if="popupOpenType === 'image'">
                                    <!-- Close button -->
                                    <button class="close-btn" @click="closePopup">X</button>
                                    <!-- Image inside the popup -->
                                    <img :src="mediaUrl(currentImageIndex)" class="popup-image"/>
                                </div>
                                <div v-else-if="popupOpenType === 'video'" class="popup-content">
                                    <!-- Image inside the popup -->
                                    <video controls class="popup-image" @click.stop>
                                        <source :src="mediaUrl(currentImageIndex)" type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="params.medias && params.medias.length < 7"
                         class="__image-box"
                         :class="{ 'form__image-error': isNoImage }"
                         @click="handleUpload('pd')"
                    >
                        <div class="__image-placeholder">
                            <v-icon>mdi-plus</v-icon> <!-- Use mdi-plus instead of $addplus -->
                            <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: left;">Photo/Video</span>
                        </div>
                    </div>
                    <div v-if="isLoading" class="__loading">
                        <v-progress-circular :indeterminate="true" color="primary" size="50"></v-progress-circular>
                    </div>
                    <span v-show="isNoImage" class="__text-alert error--text">
                        The Image/Video field is required
                    </span>
                    <div v-if="errorMessageImage" class="upload-error">
                        {{ errorMessageImage }}
                    </div>
                </div>
                <br/>
                <div class="__header">
                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Category</span>
                </div>
                <CategorySelect v-model="selectedCategory"
                                holderText="Please select"
                                :defaultOption="params.productCategoryName"
                                :options="productCategories"
                                type="id"
                                style="max-width: 400px;margin-bottom: 20px;"
                />
                <custom-input
                        label="Name"
                        name="Name"
                        rules="required"
                        v-model="params.name"
                        :value="params.name"/>
                <custom-input
                        label="SearchName"
                        v-model="params.searchName"
                        :value="params.searchName"
                        rules="required"
                        name="SearchName"
                />
                <custom-input
                        label="Description"
                        v-model="params.description"
                        :value="params.description"
                        rules="required"
                        name="Description"
                />
                <custom-input
                        label="Brand"
                        v-model="params.brand"
                        :value="params.brand"
                        rules="required"
                        name="Brand"
                />
                <div class="__header">
                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">Detail Photo</span>
                </div>
                <image-text-editor
                        label="DetailInfo"
                        v-model="params.detailInfo"
                        :value="params.detailInfo"
                        rules="required"
                        name="DetailInfo"
                />
                <br/>
                <div style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;">
                    <h3>Product Parameters Key-Value</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Key</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in params.paramsKeyValue" :key="row.index">
                                <td>{{ 'Param ' + (index + 1) }}:</td>
                                <td>
                                    <input v-model="row.key" type="text"/>
                                </td>
                                <td>
                                    <input v-model="row.value" type="text"/>
                                </td>
                                <td style="display: flex;">
                                    <button @click="addKey(index)" class="circle-button">+</button>
                                    <button @click="removeKey(index)" class="table-button">Delete</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div class="__header">
                    <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: right;">More Details of Product Parameters</span>
                </div>
                <only-text-editor
                        label="DetailParams"
                        v-model="params.detailParams"
                        :value="params.detailParams"
                        rules="required"
                        name="DetailParams"
                />
                <br/>
                <div style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;">
                    <h3>Sku Spec</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Spec Name</th>
                                <th>Spec Value</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in params.skuSpecs" :key="index">
                                <td>{{ 'Spec ' + (index + 1) }}:</td>
                                <td>
                                    <CustomSelect
                                            :key="'custom-select-spec-' + index"
                                            v-model="row.name"
                                            holderText="Please select"
                                            :defaultOption="row.name"
                                            :options="filteredOptions(index)"
                                    />
                                </td>
                                <td>
                                    <div class="selected-spec" v-if="row.values && row.values.length >= 0">
                                        <div
                                                v-for="(text, idx) in row.values"
                                                :key="idx"
                                                class="spec-module"
                                        >
                                            <span style="font-size: 12px">{{ text.name }}</span>
                                            <v-btn
                                                    icon
                                                    @click.stop="removeSpec(index, idx)"
                                                    class="remove-btn"
                                                    style="width: 16px; height: 16px; border-radius: 50%; background-color: #FFF3F5; padding: 0;"
                                            >
                                                <v-icon class="remove-icon">mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                        <div
                                                contenteditable="true"
                                                @keydown.enter="addSpecValue(index, $event)"
                                                style="font-size: 16px;text-align: center; line-height: 1.5;padding: 5px; border: 1px solid #ddd; border-radius: 4px;"
                                                ref="editableDiv"
                                        >
                                        </div>
                                    </div>
                                </td>
                                <td style="display: flex;">
                                    <button @click="addSpecRow(index)" class="circle-button">+</button>
                                    <button @click="removeSpecRow(index)" class="table-button">Delete</button>
                                    <div v-if="index === 0" style="display: flex;margin-left: 16px;justify-content: center;align-items: center;">
                                        <input type="checkbox" id="specImage" name="horns" :checked="isRequiredSpecImage"
                                               @change="handleCheckboxChange($event)"/>
                                        <label for="specImage" style="margin-left: 5px;white-space: nowrap;display: inline;margin-right: auto;">Required Spec images</label>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div v-if="isRequiredSpecImage" style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;width: 600px;">
                    <upload-oss
                            id="pdCover"
                            style="display: none"
                            @response="getCoverResponse"
                            :typeAllowed="['jpeg', 'png', 'jpg', 'gif']"
                            :maxPhotoSize="600000"
                            :videoDuration="15"
                            :limitResolution="540"
                    />
                    <h3>Spec Cover Image</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Spec Value</th>
                                <th>Upload Cover Image</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in params.skuSpecs[0].values" :key="index">
                                <td>{{ 'Cover ' + (index + 1) }}:</td>
                                <td>
                                    <input style="width: auto;min-width: 50px;text-align: left;font-size: 16px;" v-model="row.name" readonly/>
                                </td>
                                <td>
                                    <div style="position: relative;width: 80px;height: 80px;border-radius: 5px;"
                                         v-if="coverOptionList[index] && coverOptionList[index].coverMedia" @click="openCoverPopup(index)">
                                        <img :src="coverMediaUrl(index)" class="__image" @click="openCoverPopup(index)"/>
                                        <div @click.stop="removeCoverMedia(index)" class="remove-Media-btn">
                                            <v-icon class="remove-media-icon">mdi-close</v-icon>
                                        </div>
                                    </div>
                                    <div v-if="!(coverOptionList[index] && coverOptionList[index].coverMedia)" class="__cover-placeholder" @click="handleCoverUpload('pdCover', index)">
                                        <v-icon>mdi-plus</v-icon> <!-- Use mdi-plus instead of $addplus -->
                                        <span style="font-family: PingFang SC;font-size: 10px;font-weight: 500;line-height: 8.4px;letter-spacing: 0.01em;text-align: left;">Photo</span>
                                    </div>
                                    <div v-if="isCoverPopupOpen" class="popup" @click="closePopup">
                                        <div class="popup-content">
                                            <!-- Close button -->
                                            <button class="close-btn" @click="closePopup">X</button>
                                            <!-- Image inside the popup -->
                                            <img :src="coverMediaUrl(currentImageIndex)" class="popup-image"/>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;">
                    <h3>Sku Info</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th></th>
                                <th v-for="(spec, specIndex) in params.skuSpecs" :key="specIndex">{{ spec.name }}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in params.skuList" :key="row.index">
                                <td>{{ 'Sku ' + (index + 1) }}:</td>
                                <td v-for="(spec, specIndex) in params.skuSpecs" :key="'spec-' + specIndex">
                                    <CustomSelect
                                            :key="'custom-select-' + index + '-' + specIndex + '-' + row.specs[specIndex]?.optionId"
                                            v-model="row.specs[specIndex]"
                                            holderText="Please select"
                                            :options="spec.values"
                                            :defaultOption="row.specs[specIndex] ? row.specs[specIndex].name : ''"
                                            type="all"
                                    />
                                </td>
                                <td style="display: flex;">
                                    <button @click="addRow(index)" class="circle-button">+</button>
                                    <button @click="removeRow(index)" class="table-button">Delete</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div v-if="params.productAttributes && params.productAttributes.length > 0" style="background-color: #EEEEEE;padding: 10px;border-radius: 8px;width: 400px;">
                    <h3>Product Attributes</h3>
                    <div class="table-container">
                        <table>
                            <thead>
                            <tr>
                                <th style="width: 120px; text-align: right;">Name</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(attr, attrIndex) in params.productAttributes" :key="'attr-' + attrIndex">
                                <td style="width: 120px;text-align: right;">{{ attr.name }}:</td>
                                <td>
                                    <input v-model="attr.value" type="number"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br/>
                <div v-if="checkParamsReady">
                    <custom-button :loading="loading" type="submit" color="primary" @click="handleSubmit">Submit</custom-button>
                </div>
                <!-- Submit Button -->
                <div v-else>
                    <custom-button
                            :loading="false"
                            color="primary"
                            class="white--text"
                            type="submit"
                            :disabled="true"
                    >Submit</custom-button>
                </div>
                <v-snackbar v-model="snackbar" top right color="success">
                    Success Post
                </v-snackbar>
                <v-snackbar v-model="snackbarFailed" top right color="error">
                    Failed Post
                </v-snackbar>
                <div v-if="loading" class="loading-indicator">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderContent from "../../containers/HeaderContent";
    import {mapActions} from "vuex";
    import CustomSelect from '../Shop/Product/CustomSelect.vue';
    import CategorySelect from '../Shop/Product/CustomSelect.vue';

    export default {
        components: {
            HeaderContent,
            CustomSelect,
            CategorySelect,
        },
        methods: {
            ...mapActions({
                postProduct: "product/createProduct",
                updateProduct: "product/editProduct",
                getListCategory: "productCategory/getListCategory",
                getListCategoryAttr: "productCategory/getListCategoryAttr",
            }),
            handleGetListCategory() {
                return this.getListCategory()
                    .then((res) => {
                        const data = res.data;
                        this.productCategories.push(...data)
                    })
                    .catch((err) => {
                        console.log({err});
                    });
            },
            handleGetListCategoryAttr(productCategoryId) {
                const payload = {
                    id: productCategoryId
                }
                return this.getListCategoryAttr(payload)
                    .then((res) => {
                        const data = res.data;
                        if(this.params.productAttributes.length === 0) {
                            this.params.productAttributes = data.filter(res => res.type === 1).map((res, index) => {
                                return {
                                    name: res.name,
                                    attrId: res.id,
                                    value: 0,
                                    index: index + 1,
                                };
                            });
                        }
                        this.skuSpecList = data.filter(res => res.type === 2).map((res, index) => {
                            return {
                                name: res.name,
                                id: res.id,
                                type: res.type,
                                index: index + 1,
                            };
                        });
                    })
                    .catch((err) => {
                        console.log({err});
                    });
            },
            async handleSubmit() {
                const payload = JSON.parse(JSON.stringify(this.params));

                payload.skuSpecs.forEach(spec => {
                    if (spec.name !== '' && spec.attrId === 0) {
                        const matchingSpec = this.skuSpecList.find(item => item.name === spec.name);
                        if (matchingSpec) {
                            spec.attrId = matchingSpec.id;
                        }
                    }
                    spec.values = spec.values.map((res, index) => {
                        return {
                            id: res.id,
                            value: res.name,
                            optionId: res.optionId,
                            coverMedia: this.isRequiredSpecImage ? res.coverMedia : null
                        };
                    });
                });
                payload.skuList.forEach(sku => {
                    sku.specs = sku.specs.map((res, index) => {
                        return {
                            value: res.name,
                            optionId: res.optionId
                        };
                    });
                });
                payload.paramsKeyValue = payload.paramsKeyValue.map((res, index) => {
                    return {
                        key: res.key,
                        value: res.value
                    };
                });

                console.info(payload)
                this.loading = true
                let response;
                if(payload.id){
                    response = await this.updateProduct(payload);
                }else {
                    response = await this.postProduct(payload);
                }
                if (response.status === 201 || response.status === 200) {
                    this.snackbar = true;
                    setTimeout(() => {
                        this.$router.push('/product')
                        this.snackbar = false
                        this.loading = false
                    }, 1000)
                } else {
                    this.snackbarFailed = true
                    setTimeout(() => {
                        this.snackbarFailed = false
                        this.loading = false
                    }, 2500)
                }
            },
            getResponse(payload) {
                console.info("payload:", payload)
                if (payload.status === "loading") {
                    this.isLoading = true;
                    this.isNoImage = false;
                } else if (payload.status === "success") {
                    const data = {
                        ...payload,
                    };
                    const videos = this.params.medias.filter(opt => opt.type === 'video');
                    const images = this.params.medias.filter(opt => opt.type === 'image');

                    if (videos && videos.length > 0 && data.type === 'video') {
                        this.isLoading = false;
                        this.errorMessageImage = "Only one video can be uploaded.";
                        return;
                    }
                    if (images && images.length > 5 && data.type === 'image') {
                        this.isLoading = false;
                        this.errorMessageImage = "Only six images can be uploaded.";
                        return;
                    }

                    if (data.type === 'video') {
                        this.params.medias = [
                            data,
                            ...this.params.medias
                        ];
                    } else {
                        this.params.medias.push(data);
                    }
                    this.isLoading = false;
                    this.isNoImage = false;
                    this.errorMessageImage = "";
                } else if (payload.status === "failed") {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = payload.message;
                } else {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = "";
                }
            },
            getCoverResponse(payload) {
                console.info("payload:", payload)
                if (payload.status === "loading") {
                    this.isLoading = true;
                    this.isNoImage = false;
                } else if (payload.status === "success") {
                    const data = {
                        ...payload,
                    };

                    if (this.currentImageIndex && this.coverOptionList[this.currentImageIndex] && this.coverOptionList[this.currentImageIndex].length > 0) {
                        this.isLoading = false;
                        this.errorMessageImage = "Only one cover image can be uploaded.";
                        return;
                    }

                    this.$set(this.coverOptionList[this.currentImageIndex], 'coverMedia', data);

                    this.isLoading = false;
                    this.isNoImage = false;
                    this.errorMessageImage = "";
                } else if (payload.status === "failed") {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = payload.message;
                } else {
                    this.isLoading = false;
                    this.isNoImage = true;
                    this.errorMessageImage = "";
                }
            },
            handleUpload(id) {
                document.getElementById(id).click();
            },
            handleCoverUpload(id, index) {
                this.currentImageIndex = index;
                document.getElementById(id).click();
            },
            openPopup(index, type) {
                this.currentImageIndex = index;
                this.isPopupOpen = true;
                this.popupOpenType = type;
            },
            openCoverPopup(index) {
                this.currentImageIndex = index;
                this.isCoverPopupOpen = true;
            },
            closePopup() {
                this.isPopupOpen = false;
                this.isCoverPopupOpen = false;
                this.popupOpenType = "";
                this.currentImageIndex = null;
            },
            removeMedia(index) {
                this.params.medias.splice(index, 1);
            },
            removeCoverMedia(index) {
                this.coverOptionList[index].coverMedia = null;
            },
            mediaUrl(index) {
                return this.params.medias[index].url;
            },
            coverMediaUrl(index) {
                return this.coverOptionList[index].coverMedia ? this.coverOptionList[index].coverMedia.url : "";
            },
            addSpecRow(index) {
                this.params.skuSpecs.splice(index + 1, 0, {attrId: 0, name: '', values: []});
            },
            removeSpecRow(index) {
                if (this.params.skuSpecs.length > 1) {
                    this.params.skuSpecs.splice(index, 1);
                }
            },
            addRow(index) {
                const values = this.params.skuList;
                let maxId = 0;
                if (values.length > 0) {
                    maxId = Math.max(...values.map(item => item.index));
                }
                this.params.skuList.splice(index + 1, 0, {
                    index: maxId + 1,
                    specs: [],
                    stock: 0,
                });
            },
            removeRow(index) {
                if (this.params.skuList.length > 1) {
                    this.params.skuList.splice(index, 1);
                }
            },
            addKey(index) {
                const values = this.params.paramsKeyValue;
                let maxId = 0;
                if (values.length > 0) {
                    maxId = Math.max(...values.map(item => item.index));
                }
                this.params.paramsKeyValue.splice(index + 1, 0, {
                    index: maxId + 1,
                    key: "",
                    value: "",
                });
            },
            removeKey(index) {
                if (this.params.paramsKeyValue.length > 1) {
                    this.params.paramsKeyValue.splice(index, 1);
                }
            },
            removeSpec(index, idx) {
                this.params.skuSpecs[index].values.splice(idx, 1);
                this.coverOptionList.splice(idx, 1);
            },
            addSpecValue(index, event) {
                const inputValue = event.target.innerText.trim();
                if (inputValue) {
                    const values = this.params.skuSpecs[index].values;
                    const randomString = this.generateRandomString(10);
                    let maxIndex = 0;
                    if (values.length > 0) {
                        maxIndex = Math.max(...values.map(item => item.index));
                    }

                    const payload = {
                        index: maxIndex + 1,
                        name: inputValue,
                        optionId: randomString,
                        coverMedia: null
                    };
                    this.params.skuSpecs[index].values.push(payload);
                    this.coverOptionList.push(payload);
                }
                event.target.innerText = '';
                event.preventDefault();
            },
            generateRandomString(length) {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let randomString = '';
                for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(Math.random() * characters.length);
                    randomString += characters[randomIndex];
                }
                return randomString;
            },
            handleCheckboxChange(event) {
                const isChecked = event.target.checked;

                if (isChecked) {
                    this.isRequiredSpecImage = true;
                } else {
                    this.isRequiredSpecImage = false;
                }
            },
            cartesianProduct(arrays) {
                if (arrays.length === 0) return [];
                if (arrays.length === 1) return arrays[0].map((value, index) => {
                    return {
                        index: index + 1,
                        specs: [value],
                        stock: 0,
                    };
                });

                let currentIndex = 1;

                // 取第一组值和剩余部分递归
                const [firstArray, ...restArrays] = arrays;
                const restProduct = this.cartesianProduct(restArrays);

                // 拼接组合
                const result = [];
                firstArray.forEach((value) => {
                    restProduct.forEach((combination) => {
                        const payload = {
                            index: currentIndex++,
                            specs: [value, ...combination.specs],
                            stock: 0,
                        }
                        result.push(payload);
                    });
                });

                return result;
            },
            filteredOptions(currentIndex) {
                const selectedNames = this.params.skuSpecs
                    .map((row, index) => (index !== currentIndex ? row.name : null))
                    .filter((name) => name !== null);

                return this.skuSpecList.filter((option) => !selectedNames.includes(option.name));
            },
        },
        data() {
            return {
                items: [
                    {
                        text: "Manage Product",
                        disabled: false,
                        href: "/product",
                    },
                ],
                snackbar: false,
                loading: false,
                isLoading: false,
                snackbarFailed: false,
                params: {
                    id: null,
                    name: "",
                    searchName: "",
                    brand: "",
                    productCategoryId: 0,
                    productCategoryName: "",
                    description: "",
                    detailInfo: "",
                    paramsKeyValue: [],
                    detailParams: "",
                    skuSpecs: [{attrId: 0, name: '', values: []}],
                    skuList: [{
                        index: 1, specs: [], stock: 0
                    }],
                    medias: [],
                    createAt: "",
                    productAttributes: [],
                    productType: 0,
                    shopId: 0,
                    status: false,
                },
                images: [],
                productCategories: [],
                selectedCategory: "",
                skuSpecList: [],
                coverOptionList: [],
                isCreate: true,
                video: "",
                isNoImage: false,
                errorMessageImage: '',
                currentImageIndex: null,
                isPopupOpen: false,
                isCoverPopupOpen: false,
                isRequiredSpecImage: true,
                popupOpenType: "",
            };
        },
        created() {
            let { id, name, searchName, brand, description, detailInfo, paramsKeyValue, detailParams, createAt, productCategoryId, productCategoryName,
                productType, shopId, status, skuSpecs, skuList, medias, productAttributes  } = this.$route.params;

            if(skuList && skuSpecs) {
                this.params = {
                    id,
                    name,
                    searchName,
                    brand,
                    description,
                    detailInfo,
                    paramsKeyValue: JSON.parse(paramsKeyValue),
                    detailParams,
                    createAt,
                    productCategoryId,
                    productCategoryName,
                    productType,
                    shopId,
                    status,
                    skuSpecs: JSON.parse(skuSpecs),
                    skuList: JSON.parse(skuList),
                    medias: JSON.parse(medias),
                    productAttributes: JSON.parse(productAttributes),
                };
                this.isCreate = (id === null);
                this.handleGetListCategoryAttr(productCategoryId);
            }else {
                this.params = {
                    id: null,
                    name: "",
                    searchName: "",
                    brand: "",
                    productCategoryId: 0,
                    productCategoryName: "",
                    description: "",
                    detailInfo: "",
                    paramsKeyValue: [{
                        index: 1,
                        key: "",
                        value: "",
                    }],
                    detailParams: "",
                    skuSpecs: [{attrId: 0, name: '', values: []}],
                    skuList: [{
                        index: 1, specs: [], stock: 0
                    }],
                    medias: [],
                    createAt: "",
                    productAttributes: [],
                    productType: 0,
                    shopId: 0,
                    status: false,
                };
            }
            this.params.paramsKeyValue = this.params.paramsKeyValue.map((res, index) => {
                return {
                    key: res.key,
                    value: res.value,
                    index: index + 1,
                };
            });
            this.skuSpecList = this.params.skuSpecs.map((res, index) => {
                return {
                    name: res.name,
                    id: res.attrId,
                    type: 2,
                    index: index + 1,
                };
            });
            this.params.skuSpecs = this.params.skuSpecs.map((res, index) => {
                const values = res.values.map((val, idx) => {
                    const payload = {
                        name: val.value,
                        value: val.value,
                        id: val.id,
                        optionId: val.optionId,
                        coverMedia: val.coverMedia,
                        index: idx + 1,
                    };
                    if(index === 0) {
                        this.coverOptionList.push(payload);
                        if(val.coverMedia){
                            this.isRequiredSpecImage = true;
                        }
                    }
                    return payload;
                }).sort((a, b) => a.id - b.id);
                return {
                    name: res.name,
                    attrId: res.attrId,
                    values: values
                };
            });
            this.params.skuList = this.params.skuList.map((res, index) => {
                const specs = res.specs.map((val, idx) => {
                    return {
                        name: val.value,
                        value: val.value,
                        id: val.id,
                        optionId: val.optionId,
                        index: idx + 1,
                    };
                });
                return {
                    id: res.id,
                    stock: res.stock,
                    specs: specs,
                    index: index + 1,
                };
            });
        },
        mounted() {
            this.handleGetListCategory()
        },
        watch: {
            'selectedCategory'(newVal, oldVal) {
                this.params.productCategoryId = newVal;
                this.handleGetListCategoryAttr(newVal)
            },
            "params.skuSpecs": {
                handler(newValue, oldValue) {
                    if (this.isCreate === false) {
                        return;
                    }
                    const specs = this.params.skuSpecs.map((spec) => spec.values);
                    if (specs.length === 0) {
                        return;
                    }

                    this.params.skuList = this.cartesianProduct(specs);
                },
                deep: true,
                immediate: true,
            },
        },
        computed: {
            checkParamsReady() {
                if(this.isRequiredSpecImage){
                    const matchingSpec = this.params.skuSpecs[0].values.find(item => item.coverMedia === null);
                    return matchingSpec === undefined;
                }
                return  this.params.name !== '' &&
                    this.params.searchName !== '' &&
                    this.params.medias !== '' &&
                    this.params.medias.length !== 0 &&
                    this.params.productCategoryId !== 0 &&
                    this.params.description !== '' &&
                    this.params.detailInfo !== '' &&
                    this.params.paramsKeyValue !== '' &&
                    this.params.paramsKeyValue.length !== 0 &&
                    this.params.detailParams !== '' &&
                    this.params.skuSpecs !== '' &&
                    this.params.skuSpecs.length !== 0 &&
                    this.params.skuSpecs[0].name !== '' &&
                    this.params.skuSpecs[0].values !== '' &&
                    this.params.skuSpecs[0].values.length !== 0 &&
                    this.params.skuList !== '' &&
                    this.params.skuList.length !== 0 &&
                    this.params.skuList[0].specs !== '' &&
                    this.params.skuList[0].specs.length !== 0 &&
                    this.params.skuList[0].specs[0].name !== '';
            },
        }
    };
</script>

<style lang="scss" scoped>
    .__header {
        text-align: left;
        margin-bottom: 8px;
    }

    .left-panel {
        width: 1400px;
        padding: 20px;
        overflow-y: auto;
    }

    .left-panel ul {
        list-style-type: none;
        padding: 0;
    }

    .left-panel li {
        margin: 5px 0;
    }

    .right-panel {
        width: 400px;
        padding: 20px;
    }

    .__image-list {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        gap: 8px;
        width: 100%;
    }

    .__image-wrapper {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 5px;
    }

    .remove-Media-btn {
        color: #FFFFFF;
        width: 13px;
        height: 13px;
        border-radius: 2px;
        background: #0000004D;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .remove-media-icon {
        font-size: 8px !important;
        color: white !important;
    }


    .__image-box {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px dashed #CECECE;
        cursor: pointer;
        border-radius: 5px;
        margin: 5px;
    }

    .__image-placeholder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #ccc;
        font-size: 24px;
    }

    .__cover-placeholder {
        border: 0.5px dashed #CECECE;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 40px;
        color: #ccc;
        font-size: 24px;
    }

    .__image {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .__image-no {
        width: 100%;
        height: 100%;
        background-color: #EEEEEE;
        border-radius: 5px;
    }

    .__box {
        width: 400px;
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .popup-content {
        position: relative;
        width: 300px;
        height: 424px;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background: #f00;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .__loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

    .__text-alert {
        font-size: 12px;
        color: red;
    }

    .upload-error {
        font-size: 11px;
        font-weight: 400;
        color: $warning;
        padding: 0 20px;
    }

    .table-container {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 5px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: none;
    }

    table th, table td {
        padding: 8px;
        text-align: left;
    }

    table input {
        width: 100%;
        border: 1px solid #ccc;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    table select {
        width: 100%; /* 使 select 元素填充单元格宽度 */
        height: 30px; /* 设置高度 */
        border: 1px solid #ccc; /* 边框样式 */
        border-radius: 8px; /* 圆角边框 */
        background-color: #fff; /* 背景颜色 */
        padding: 0 10px; /* 内边距 */
        box-sizing: border-box; /* 包含内边距和边框在宽度和高度中 */
        font-size: 14px; /* 字体大小 */
        color: #333; /* 字体颜色 */
        cursor: pointer; /* 鼠标悬停时显示手指光标 */
    }

    .table-button {
        background-color: #F4F4F4;
        border: none;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 4px;
        padding: 6px 10px;
        transition: background-color 0.3s ease;
    }

    .circle-button {
        color: #ff0000; /* '+' 号的颜色为红色 */
        border: none; /* 移除默认边框 */
        border-radius: 50%; /* 圆形按钮 */
        width: 30px; /* 按钮的宽度 */
        height: 30px; /* 按钮的高度 */
        display: flex; /* 使用 Flexbox 布局 */
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        font-size: 20px; /* '+' 号的字体大小 */
        cursor: pointer; /* 鼠标悬停时显示手指光标 */
    }

    .footer-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    .footer-actions button {
        width: 94px;
        height: 30px;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 4px;
        transition: background-color 0.3s ease;
    }

    .footer-actions button:first-of-type {
        background-color: #F4F4F4;
        color: #333;
    }

    .footer-actions button:last-of-type {
        background-color: #FF4265;
    }

    .selected-spec {
        display: flex;
        flex-wrap: wrap;
        border: 0.5px solid #CECECE;
        border-radius: 8px;
    }

    .spec-module {
        display: flex;
        align-items: center;
        background-color: #FFF3F5;
        border-radius: 16px;
        padding: 2px 2px;
        margin: 5px;
        border: 0.2px solid #FF617E;
    }

    .remove-btn {
        width: 5px;
        height: 5px;
        border-radius: 2px;
        background-color: #FFF3F5;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }

    .remove-icon {
        font-size: 13px !important;
        color: #8B8B8B !important;
    }

    .editable-div {
        min-width: 100px;
        padding: 5px;
        border: 1px solid #CECECE;
        border-radius: 8px;
        font-size: 12px;
        margin-top: 5px;
    }

    .loading-indicator {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* 确保在最上层 */
    }

    .spinner {
        border: 8px solid #f3f3f3; /* 边框颜色 */
        border-top: 8px solid red; /* 上边框红色 */
        border-radius: 50%;
        width: 50px; /* 宽度 */
        height: 50px; /* 高度 */
        animation: spin 1s linear infinite; /* 旋转动画 */
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>
