<template>
  <div>
    <HeaderContent label="Edit Activity" :list="items" />
    <div class="mt-4" />
    <FormAd
      :ad="ad"
      @onSubmit="onSubmit"
      labelButton="Edit Activity"
      :loading="loading"
      status="edit"
    />
    <v-snackbar top right v-model="alertSuccess" color="success">
      Post Success
    </v-snackbar>
    <v-snackbar top right v-model="alertError" color="error">
      {{errorMessage}}
    </v-snackbar>
  </div>
</template>

<script>
import HeaderContent from "../../../containers/HeaderContent";
import FormAd from "../../../containers/Form/formAd";
import { mapActions } from "vuex";
export default {
  components: {
    HeaderContent,
    FormAd
  },
  data() {
    return {
      ad: null,
      loading: false,
      alertSuccess : false,
      alertError : false,
      errorMessage: 'Post Failed',
      items: [
        {
          text: "Manage Advertising",
          disabled: false,
          href: "/post/ad"
        },
        {
          text: "Edit Advertising",
          disabled: true
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      editAd: "ad/editAd",
    }),
    async onSubmit(payload) {
      this.loading = true;
      const response = await this.editAd(payload);
      if (response.status === 200) {
				this.alertSuccess = true
				setTimeout(() => {
					this.$router.push("/post/ad");
					this.alertSuccess = false
				},500)
        this.loading = false;
      } else {
        const responseString = JSON.stringify(response, null, 2);
        if(responseString.includes('status code 400')){
          this.errorMessage = 'Kamu belum pernah memposting video,jadi kamu tidak dapat memasang iklan.'
        }
				this.loading = false;
				this.alertError = true
				setTimeout(() => {
					this.alertError = false
				},3000)
      }
    },
    loadAdFromRoute() {
      let { id, adTitle, adStatus, duration, remainingTime, medias, createAt, accountIds, adCampaignType, accountType, isOfficial } = this.$route.params;

      const parsedMedias = medias ? JSON.parse(medias) : [];
      const parsedAccountIds = accountIds ? JSON.parse(accountIds) : [];
      let stringAccountIdsArrow = parsedAccountIds.map(id => id.toString());
      isOfficial = String(isOfficial);

      this.ad = {
        id,
        adTitle,
        adStatus,
        duration,
        remainingTime,
        medias: parsedMedias,
        createAt,
        accountIds: stringAccountIdsArrow,
        adCampaignType,
        accountType,
        isOfficial
      };
    },
  },
  created() {
    // Fetch the ad details from route params
    this.loadAdFromRoute();
  },
};
</script>
