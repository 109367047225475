import { render, staticRenderFns } from "./formChannel.vue?vue&type=template&id=06ab2fc6&scoped=true"
import script from "./formChannel.vue?vue&type=script&lang=js"
export * from "./formChannel.vue?vue&type=script&lang=js"
import style0 from "./formChannel.vue?vue&type=style&index=0&id=06ab2fc6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ab2fc6",
  null
  
)

export default component.exports