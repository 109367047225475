<template>
    <div class="custom-select" ref="dropdownContainer">
        <div class="selected" @click="toggleDropdown">
            {{ selectedOption || holderText }}
            <span class="arrow">
                <svg width="11" height="7" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.57422 4.42578C3.38281 4.42578 3.22266 4.35547 3.0625 4.19531L0.171875 1.23828C0.0546875 1.11719 -0.00390625 0.976562 -0.00390625 0.808594C-0.00390625 0.460938 0.273438 0.179688 0.617188 0.179688C0.789062 0.179688 0.949219 0.25 1.07812 0.378906L3.57812 2.95703L6.07422 0.378906C6.20312 0.25 6.36328 0.179688 6.53125 0.179688C6.875 0.179688 7.15234 0.460938 7.15234 0.808594C7.15234 0.980469 7.09375 1.12109 6.97656 1.23828L4.08594 4.19531C3.93359 4.35156 3.76953 4.42578 3.57422 4.42578Z"
      fill="#B8B8B8"/>
</svg>
            </span>
        </div>
        <ul v-if="isOpen" class="dropdown">
            <li v-for="option in options" :key="option.id" @click="selectOption(option)">
                {{ option.name }}
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'CustomSelect',
        props: {
            options: {
                type: Array,
                required: true
            },
            holderText: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                isOpen: false,
                selectedOption: null
            };
        },
        methods: {
            toggleDropdown(event) {
                event.stopPropagation();
                this.isOpen = !this.isOpen;
            },
            selectOption(option) {
                this.selectedOption = option.name;
                this.$emit('input', option.id);
                this.isOpen = false;
            },
            handleOutsideClick(event) {
                const dropdown = this.$refs.dropdownContainer;
                if (!dropdown.contains(event.target)) {
                    this.isOpen = false;
                }
            }
        },
        mounted() {
            if(this.options.length > 0) {
                this.selectedOption = this.options[0].name;
                this.$emit('input', this.options[0].id);
            }
            document.addEventListener('click', this.handleOutsideClick);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleOutsideClick);
        },
        watch: {
            options(newOptions) {
                if (newOptions.length > 0) {
                    this.selectedOption = newOptions[0].name;
                    this.$emit('input', newOptions[0].id);
                } else {
                    this.selectedOption = null;
                }
            },
            selectedOption(newVal) {
                if (newVal === null && this.options.length > 0) {
                    this.selectedOption = this.options[0].name;
                }
            }
        }
    };
</script>

<style scoped>
    .custom-select {
        position: relative;
        width: 100%;
    }

    .selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        background-color: #fff;
        box-sizing: border-box;
        font-size: 14px;
    }

    .arrow {
        display: flex; /* Ensure arrow is a flex item */
        align-items: center; /* Center vertically */
        justify-content: center;
        width: 20px;
        height: 20px;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        max-height: 200px;
        overflow-y: auto;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .dropdown li {
        padding: 8px;
        cursor: pointer;
        font-size: 14px;
    }

    .dropdown li:hover {
        background-color: #FFF3F5;
    }
</style>
